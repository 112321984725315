import { useState } from 'react'
import {projectStorage, ref, uploadBytesResumable,getDownloadURL, deleteObject} from  '../firebase/config'
import { useFireStore } from './useFirestore'
import { useNavigate } from 'react-router-dom'


export const useStorage =(coll,storageFolder)=>{
    const navigate=useNavigate();
    const [imageUploadProgress, setImageUploadProgress] = useState('')

    const {setDocument,addDocument,response}=useFireStore(coll)

    const [isPending, setIsPending] = useState(false)

    const [firstCertificateImageURL, setfirstCertificateImageURL] = useState(null)
    const [secondCertificateImageURL, setSecondCertificateImageURL] = useState(null)
    const [newsImageURL, setNewsImageURL] = useState('')


    const uploadMultiImagesAndAboutContent =async (image1,image2,aboutContent,imgRef1,imgRef2)=>{

        const deleteNewsImage=(imgToDelPath)=>{
            const imgToDelRef=ref(projectStorage,`${imgToDelPath}`)
            console.log(imgToDelRef,imgToDelRef);
            deleteObject(imgToDelRef).then(()=>{
                console.log("img Deleted");
             }).catch((err)=>{
                console.log(err);
             })
        }
        if(imgRef1){
            deleteNewsImage(imgRef1)
        }
        if(imgRef2){
            deleteNewsImage(imgRef2)
        }

        const rando1=Math.floor(Math.random()*152634)
        const rando2=Math.floor(Math.random()*152634)
        setIsPending(true)

        const imgpath1=`${storageFolder}/${rando1}${image1.name}`
        const imgpath2=`${storageFolder}/${rando2}${image2.name}`
        const uploadPath1 = ref(projectStorage,imgpath1)
        const uploadPath2 = ref(projectStorage,imgpath2)

        const uploadTask1 =uploadBytesResumable(uploadPath1,image1)
        const uploadTask2 =uploadBytesResumable(uploadPath2,image2)
        
        
        uploadTask1.on("state_changed",(snapshot)=>{
            const progress= (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  
            switch(snapshot.state){
                case "paused":
                     console.log("Upload is paused")
                break;
                case "running":
                    setImageUploadProgress(Math.floor(progress+1))
                break;
                default:
                        return snapshot.state
            }
        
        },(error)=>{
            console.log(error);
        },async ()=>{

          await  getDownloadURL(uploadTask1.snapshot.ref).then((downloadURL1)=>{

                setImageUploadProgress(0)

                setfirstCertificateImageURL(downloadURL1) 

                //  addDocument(aboutContent,downloadURL,imgpath1)
                //  if(!response.error){
                //     if(coll==='news'){
                //     navigate("/management/admin/")
                //     setIsPending(false)
                //     }
                //     if(coll==='products'){
                //         navigate("/management/admin/products")
                //         setIsPending(false)
                //     }
            
                //  }
                console.log("first IMage done");
                 if(response.error){
                    console.log('file not uploaded');
                 }
              });
              uploadTask2.on("state_changed",(snapshot)=>{
                const progress= (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      
                switch(snapshot.state){
                    case "paused":
                         console.log("Upload is paused")
                    break;
                    case "running":
                        setImageUploadProgress(Math.floor(progress+1))
                    break;
                    default:
                            return snapshot.state
                }
                console.log("2nd IMage done");
            
            },(error)=>{
                console.log(error);
            },async ()=>{
    
              await  getDownloadURL(uploadTask2.snapshot.ref).then((downloadURL2)=>{
    
                    setImageUploadProgress(0)
    
                    setDocument(aboutContent,downloadURL2,imgpath2,firstCertificateImageURL,imgpath1)
                     if(!response.error){
                        if(coll==='news'){
                        navigate("/management/admin/")
                        setIsPending(false)
                        }
                        if(coll==='products'){
                            navigate("/management/admin/products")
                            setIsPending(false)
                        }
                        if(coll==='aboutPage'){
                            navigate("/management/admin/about-page")
                            setIsPending(false)
                        }
                
                     }
                     if(response.error){
                        console.log('file not uploaded');
                     }
                  });
          
            })
    
      
        });

   
        
    }

    const uploadImageAndCreateDocument = async (image,project)=>{
        const rando=Math.floor(Math.random()*152634)
        setIsPending(true)
        const imgpath=`${storageFolder}/${rando}${image.name}`
        const uploadPath = ref(projectStorage,imgpath)
        
        const uploadTask =uploadBytesResumable(uploadPath,image)
      
        
        uploadTask.on("state_changed",(snapshot)=>{
            const progress= (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  
            switch(snapshot.state){
                case "paused":
                     console.log("Upload is paused")
                break;
                case "running":
                    setImageUploadProgress(Math.floor(progress+1))
                break;
                default:
                        return snapshot.state
            }
        
        },(error)=>{
            console.log(error);
        },async ()=>{

          await  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL)=>{

                setImageUploadProgress(0)

                setNewsImageURL(downloadURL) 
                 addDocument(project,downloadURL,imgpath)
                 if(!response.error){
                    if(coll==='news'){
                    navigate("/management/admin/")
                    setIsPending(false)
                    }
                    if(coll==='products'){
                        navigate("/management/admin/products")
                        setIsPending(false)
                    }
            
                 }
                 if(response.error){
                    console.log('file not uploaded');
                 }
              });
      
        }
        
        );

}



return {uploadImageAndCreateDocument,newsImageURL,imageUploadProgress,isPending ,uploadMultiImagesAndAboutContent}
}

