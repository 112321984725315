import { useState, useEffect } from 'react'
import Select from 'react-select'
import {useCollection} from "../../../hooks/useCollection"
import {useAuthContext} from "../../../hooks/useAuthContext"
import { useStorage } from '../../../hooks/useStorage'
import './CreateNews.css'
import { useNavigate } from 'react-router-dom'
import { Timestamp, timeStamp } from '../../../firebase/config'


export default function Create() {
  const {uploadImageAndCreateDocument,imageUploadProgress,isPending}=useStorage('news','newsImages')

  const {user} =useAuthContext()
  
  //Use State Form Fields
  const [title, setTitle] = useState('')
  const [details, setDetails] = useState('')
  const [ date  , setDate  ] = useState('') 
  const [formError, setFormError] = useState(null)
  const [newsImageError, setNewsImageError] = useState(null)
  const [newsImage, setNewsImage] = useState(null)

  
  
  const handleFileChange = e => {
    setNewsImage(null)
    let selected = e.target.files[0]
    console.log(selected);
    if(!selected){
      setNewsImageError("Please select a file");
    return 
    }
    if(!selected.type.includes("image")){
      setNewsImageError("Please select an image");
      return 
    }
    if(selected.size > 4194304){
      setNewsImageError("Please select an image less than 4mb");
      return
    }
    setNewsImageError(null)
    setNewsImage(selected)
    
  }


  const handleSubmit = async (e) => {
    
    e.preventDefault()
    setFormError(null)

    const createdBy ={
      displayName:user.displayName,
      photoURL:user.photoURL,
      id:user.uid
    }

    const news={
      title,
      details,
      date:Timestamp.fromDate(new Date(date)),
      createdBy,   
      comments:[]
    }
    await uploadImageAndCreateDocument(newsImage,news)
    
  }
  

  return (
    <div className=' create-news' onSubmit={handleSubmit} style={{textAlign:`end`}}>

    <form>
    <label>
        <span>صورة الخبر</span>
        <input
          required
          type="file"
          onChange={handleFileChange}
        />
        {newsImageError && <p className="error">{newsImageError}</p>}
      </label>
    <label >
      <span>العنوان</span>
      <input
       required 
      type="text"
      onChange={(e) => setTitle(e.target.value)}
      value={title}
       />
    </label>
    <label >
      <span>تفاصيل الخبر</span>
      <textarea
        required
        onChange={(e) => setDetails(e.target.value)}
        value={details}
      ></textarea>
    </label>
    <label >
      <span>التاريخ</span>
      <input
        required
        type="date"
        onChange={(e) => setDate(e.target.value)}
        value={date}
      />
    </label>
 
 

    {isPending &&(<>

       <button className="btn" type="submit" disabled>جاري التحميل</button>
        <div className="progress-bar" style={{width:imageUploadProgress+'%',height:`2em`,backgroundColor:`var(--primary-color)`,transition:`width 4s`}}>
       </div>
       </>)
       }
     {!isPending&& <button className="btn" type="submit">انشاء</button>}

    
    {formError && <p className="error">{formError}</p>}
    </form>

    </div>
  )
}
