import { Navigate, Outlet, Route } from "react-router-dom";

import Footer from './public-pages/Footer/Footer'
import PublicNavBar from "./public-pages/NavBar/PublicNavBar";

export default  function PrivateRoutes () {

       
          return (<>
            <PublicNavBar/>
            <Outlet/>
            <Footer/>
          </>)
        
       
      
    }
   
