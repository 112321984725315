import { useEffect,useState } from "react"
import { doc,collection, onSnapshot, projectFirestore } from "../firebase/config"
 
 export const useDocument =(coll,id)=>{
    const [document,setDocument]=useState(null)
    const [error,setError]=useState(null)
    const [isPending,setIsPending]=useState(true)

    //realtime data for documents
    useEffect(()=>{
        let q = collection(projectFirestore, coll);


const unsub = onSnapshot(doc(projectFirestore, coll, id), (snap) => {
   
    if (snap.data()) {
        setDocument({ ...snap.data(), id: snap.id });
        setError(null);
        setIsPending(false);
    } else {
        setError("Document does not exist!");
    }
           
        }, err =>{
            console.log(err.message)
            setError("Could not get Document")
        })
        return ()=>unsub()
    }
    ,[coll,id])
    return {document,error,isPending}
 }