import { useEffect, useState } from "react";
import { Link, Router, Route } from "react-router-dom";

import "./NewsList.css";
import ReadMore from "../ReadMore/ReadMore";
const noNewsStyle = {
  position: "absolute",
  top: "50%",
  left: "55%",
};
export default function NewsList({ news }) {
  return (
    <div className="project-list">
      {news.length === 0 && <p style={noNewsStyle}>لا يوجد اخبار </p>}
      {news.map((newss, index) => {
        return (
          <div key={index}>
            <img
              style={{ width: `100%`, height: `275px` }}
              src={newss.imageURL}
              alt=""
            />
            <h4>{newss.title}</h4>
            <p>{newss.date.toDate().toLocaleDateString("ar-IQ")}</p>
            <ReadMore limit={160}>{newss.details}</ReadMore>

          
              <Link
                to={`/management/admin/project/${newss.id}`}
                key={newss.id}
              > تعديل</Link>{" "}
          </div>
        );
      })}
    </div>
  );
}
