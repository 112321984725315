import { useState } from 'react'

export default function ReadMore({limit,children}) {
    const [isShown, setIsShown] = useState(false)


    const  text=children
    const toggleShown=()=>{
        setIsShown(prevState=>!prevState)
    }

  return (

    <p>
      {isShown ?text: text.substring(0,limit)}
      
      {text.length >450 &&  <em className='read-more' onClick={toggleShown}>{isShown ? '  اظهر اقل  ' :'  ...اقرأ المزيد'}  </em> }
    
     </p>

  )
}
