import React, { useState } from 'react'
import { useCollection } from '../../../hooks/useCollection'
import { useFireStore } from '../../../hooks/useFirestore'
import useUpdateNews from '../../../hooks/useUpdateNews'
import './EditAbout.css'

export default function EditAbout() {
  const {  uploadNewNewsImage } = useUpdateNews();

  const {documents:aboutPage,isPending,error}=useCollection('publicPages')

  console.log(aboutPage)

  const { setDocument } = useFireStore("publicPages");

  const [aboutFactory, setAboutFactory] = useState("");
  const [firstCertificateDescription, setFirstCertificateDescription] =   useState("");
  const [secondCertificateDescription, setSecondCertificateDescription] =useState("");
  const [productionPower,setProductionPower]=useState('')


  const [firstCertificateImage, setFirstCertificateImage] = useState(null);
  const [firstCertificateImageErr, setFirstCertificateImageErr] = useState("");
  const [firstCertificateTitle, setFirstCertificateTitle] = useState('')

  const [secondCertificateImage, setSecondCertificateImage] = useState(null);
  const [secondCertificateImageErr, setSecondCertificateImageErr] = useState("");
  const [secondCertificateTitle, setSecondCertificateTitle] = useState('')


  const [isEditingContent, setIsEditingContent] = useState(false);




  const handleFileChangeFirstImage = (e) => {
    setFirstCertificateImage(null);
    let selected = e.target.files[0];
    if (!selected) {
      setFirstCertificateImageErr(" من فضلك اختر صورة للشهادة الاولى ");
      return;
    }
    if (!selected.type.includes("image")) {
      setFirstCertificateImageErr(" من فضلك اختر صورة للشهادة الاولى ");
      return;
    }
    if (selected.size > 4194304) {
      setFirstCertificateImageErr("من فضلك اختر صورة بحجم اقل من 4 ميجابايت");
      return;
    }
    setFirstCertificateImageErr(null);
    setFirstCertificateImage(selected);
  };

  const handleFileChangeSecondImage = (e) => {
    setSecondCertificateImage(null);
    let selected = e.target.files[0];
    console.log(selected);
    if (!selected) {
      setSecondCertificateImageErr("من فضلك اختر صورة ");
      return;
    }
    if (!selected.type.includes("image")) {
      setSecondCertificateImageErr("من فضلك اختر صورة ");
      return;
    }
    if (selected.size > 4194304) {
      setSecondCertificateImageErr("من فضلك اختر صورة بحجم اقل من 4 ميجابايت");
      return;
    }
    setSecondCertificateImageErr(null);
    setSecondCertificateImage(selected);
  };

  
  const handleEditingInfo = () => {
    setAboutFactory(aboutPage[0].aboutFactory);
    setFirstCertificateDescription(aboutPage[0].firstCertificateDescription);
    setSecondCertificateDescription(aboutPage[0].secondCertificateDescription);
    setProductionPower(aboutPage[0].productionPower)
    setFirstCertificateTitle(aboutPage[0].firstCertificateTitle)
    setSecondCertificateTitle(aboutPage[0].secondCertificateTitle)
    setIsEditingContent(true);
  };

 //SECTION - save edits
  const handleSaveAbout = (e) => {
    e.preventDefault();

    const aboutPageInfo = {
      aboutFactory,
      firstCertificateDescription,
      firstCertificateTitle,
      secondCertificateDescription,
      secondCertificateTitle,
      productionPower
    };
    setDocument(aboutPageInfo, "aboutPage");
    setIsEditingContent(false)
  };

  const handleSaveFirstCertificate = () => {
    const rando = Math.floor(Math.random() * 152634);
    if (firstCertificateImage !== null) {
      uploadNewNewsImage(
        "aboutPage",
        "publicPages",
        firstCertificateImage,
        aboutPage[0].imgRef1,
        `certifiactesImages/${rando}${firstCertificateImage.name}`,
        1
      );
    }
    setFirstCertificateImage(null);
  };

  

  const handleSaveSecondCertificate = () => {
    const rando = Math.floor(Math.random() * 152634);
    if (secondCertificateImage !== null) {
      uploadNewNewsImage(
        "aboutPage",
        "publicPages",
        secondCertificateImage,
        aboutPage[0].imgRef2,
        `certifiactesImages/${rando}${secondCertificateImage.name}`,
        2
      );
    }
    setSecondCertificateImage(null);
  };

  const handleCancelImage1=()=>{
    setFirstCertificateImage(null)

  }
  const handleCancelImage2 =()=>{
    setSecondCertificateImage(null)
  }

  //     <input
  //     id="second-certificate"
  //     name='second-certificate'
  //     required
  //     type="file"
  //     onChange={handleFileChangeSecondImage}
  //    />
  //    <input
  //     id="first-certificate"
  //     name='first-certificate'
  //     required
  //     onChange={handleFileChangeFirstImage}
  //     type="file"

  //     />
  //    <label className="btn" htmlFor="first-certificate">
  //      {" "}
  //      صورة الشهادة الاولى

  //    </label>

  //    <label className="btn" htmlFor="second-certificate">
  //      {" "}
  //      صورة الشهادة الثانية
  //    </label>
  //    {firstCertificateImageErr && <span className='error'>{firstCertificateImageErr}</span>}
  //    {secondCertificateImageErr && <span className='error'>{secondCertificateImageErr}</span>}
  //     console.log(aboutPage);
  return (
    <>    {error && <p className='error'>{error}</p>}

      {aboutPage && !isPending && (
        <>
          {!isEditingContent && (
            <>
              <div className="about-page-edit" >
                <p>{aboutPage[0].aboutFactory}</p>
                <h3>حول المعمل</h3>

                <p>{aboutPage[0].firstCertificateDescription}</p>
                <h3>وصف الشهادة الاولى</h3>

                <p>{aboutPage[0].secondCertificateDescription}</p>
                <h3>وصف الشهادة الثانية</h3>

                <p>{aboutPage[0].productionPower}</p>
                <h3>الطاقة الانتاجية</h3>

                <p>{aboutPage[0].firstCertificateTitle}</p>
                <h3>اسم الشهادة الاولى</h3>

                <p>{aboutPage[0].secondCertificateTitle}</p>
                <h3>اسم الشهادة الثانية</h3>

                <button className="btn" onClick={handleEditingInfo}>
                  {" "}
                  تعديل النصوص
                </button>
                <br />
                <div>
                  <label>
                    <span className="btn"> تغيير صورة الشهادة الاولى </span>
                    <input type="file" onChange={handleFileChangeFirstImage} />
                   
                  </label>
                  {firstCertificateImageErr && (
                      <p
                        className="error"
                        style={{
                          position: "absolute",
                          WebkitFilter: "blur(0px) saturate(2)",
                        }}
                        onClick={()=>setFirstCertificateImageErr(false)}
                      >
                        {firstCertificateImageErr}
                      </p>
                    )}
                  {!firstCertificateImageErr && firstCertificateImage && (
                    <div
                      style={{
                        display: "flex",
                        gap: `1rem`,
                        position: "absolute",
                        WebkitFilter: "blur(0px) saturate(2)",
                      }}
                    >
                      <button
                        className="btn"
                        onClick={handleSaveFirstCertificate}
                      >
                        {" "}
                        حفظ هذه الصورة
                      </button>
                      <button className="btn" onClick={handleCancelImage1}>
                        الغاء
                      </button>
                    </div>
                  )}
                </div>

                <img
                  src={aboutPage[0].imageURL1}
                  alt=""
                  
                />
                <div>
                  <label>
                    <span className="btn">تغيير صورة الشهادة الثانية</span>
                    <input type="file" onChange={handleFileChangeSecondImage} />
                 
                  </label>
                  {secondCertificateImageErr && (
                      <p
                        className="error"
                        style={{
                          position: "absolute",
                          WebkitFilter: "blur(0px) saturate(2)",
                        }}
                        onClick={()=>setSecondCertificateImageErr(false)}
                      >
                        {secondCertificateImageErr}
                      </p>
                    )}
                  {!secondCertificateImageErr && secondCertificateImage && (
                    <div
                      style={{
                        display: "flex",
                        gap: `1rem`,
                        position: "absolute",
                        WebkitFilter: "blur(0px) saturate(2)",
                      }}
                    >
                      <button
                        className="btn"
                        onClick={handleSaveSecondCertificate}
                      >
                        {" "}
                        حفظ هذه الصورة
                      </button>
                      <button className="btn" onClick={handleCancelImage2}>
                        الغاء
                      </button>
                    </div>
                  )}
                </div>

                <img
                  src={aboutPage[0].imageURL2}
                  alt=""
                />
              
              </div>
            </>
          )}
          {isEditingContent && (
            <>
              <form className="about-page-edit-content" onSubmit={handleSaveAbout}>
                <input
                  required
                  id="about-klf"
                  name="about-klf"
                  type="text"
                  value={aboutFactory}
                  placeholder=""
                  onChange={(e) => setAboutFactory(e.target.value)}

                />
                <label htmlFor="about-klf">
                  {" "}
                  <h3>حول المعمل</h3>
                </label>

               

                <input
                  required
                  id="first-certificate-title"
                  name="first-certificate-title"
                  type="text"
                  value={firstCertificateTitle}
                  placeholder=""
                  onChange={(e) => setFirstCertificateTitle(e.target.value)}
                />
                 <label htmlFor="first-certificate-title">
                  {" "}
                  <h3>اسم الشهادة الاولى</h3>
                </label>
                
                <input
                  required
                  id="second-certificate-title"
                  name="second-certificate-title"
                  type="text"
                  value={secondCertificateTitle}
                  placeholder=""
                  onChange={(e) => setSecondCertificateTitle(e.target.value)}
                  style={{display:`block`}}
                />

                  <label htmlFor="second-certificate-title">
                  {" "}
                  <h3>اسم الشهادة الثانية</h3>
                </label>
                <textarea
                  id="first-certificate-description"
                  name="first-certificate-description"
                  value={firstCertificateDescription}
                  required
                  onChange={(e) =>
                    setFirstCertificateDescription(e.target.value)
                  }
                />

                <label htmlFor="first-certificate-description">
                  <h4>وصف الشهادة الاولى</h4>
                </label>
                <textarea
                  id="production-power"
                  name="production-power"
                  required
                  value={productionPower}
                  onChange={(e) => setProductionPower(e.target.value)}
                />
                <label htmlFor="production-power">
                  <h4>الطاقة الانتاجية</h4>
                </label>

                <textarea
                  id="second-certificate-description"
                  name="second-certificate-description"
                  required
                  value={secondCertificateDescription}
                  onChange={(e) =>
                    setSecondCertificateDescription(e.target.value)
                  }
                />
                <label htmlFor="second-certificate-description">
                  <h4>وصف الشهادة الثانية</h4>
                </label>

                <button className="btn">حفظ التعديلات</button>
                <button
                  className="btn"
                  onClick={() => setIsEditingContent((prevState) => !prevState)}
                  type="button"
                >
                  الغاء التعديل
                </button>
              </form>
            </>
          )}
        </>
      )}
    </>
  );
}
