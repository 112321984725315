import { useState ,useEffect} from "react"
import { projectAuth,doc,updateDoc,projectFirestore,signInWithEmailAndPassword, multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator,
    RecaptchaVerifier, getMultiFactorResolver} from "../firebase/config"
import { useAuthContext } from "./useAuthContext"
import { useNavigate } from "react-router-dom"
    let resolver       
    

export const useLogin=()=>{
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const [isAskingCode,setIsAskingCode]=useState(false)
    const [veriId, setVeriId] = useState(null)
    const {dispatch} = useAuthContext()
    const [userIsVerified,setUserIsVerified]=useState(false)


    const navigate=useNavigate()
    

    const sendCode = () => {
      let recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        undefined,
        projectAuth
      );

      resolver = getMultiFactorResolver(projectAuth, error);

      if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        const phoneAuthProvider = new PhoneAuthProvider(projectAuth);
        //send sms verification code
        return phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
          .then((verificationId) => {
    
            console.log(verificationId);
            setVeriId(verificationId)

            recaptchaVerifier.clear()
          }).catch((veriPhoneErr)=>{
                console.log(veriPhoneErr)
          });
        
      }

   
    };

   

    const verifyLogin = async (code, verificationId) => {
      
      let verfiyWithId = veriId;
      const verificationCode = code;
      console.log(verfiyWithId, verificationCode);

      //ask user for the sms verification code. then:
      //create a phone credential with the verification id and the code.

      const cred = PhoneAuthProvider.credential(verfiyWithId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

           //complete sign-in.

      return resolver.resolveSignIn(multiFactorAssertion).then(
        //after user submited the code successfully  signs in 
        async function (userCredential) {
          //     //user successfully signed in with the second factor phone number.
          await updateDoc(
            doc(projectFirestore, "users", `${projectAuth.currentUser.uid}`),
            {
              online: true,
            }
          );
                            
          dispatch({ type: "LOGIN", payload: userCredential.user });
          //
          navigate("/management/admin/");
        }
        //if there is error it get console.logged 
      ).catch((verifyError) => {
        console.log(verifyError);
      });
    };
   
    const login = async (email,password)=>{
       

        setError(null)
        setIsPending(true)
        //sign user In
        try {
           
            const res=await signInWithEmailAndPassword(projectAuth,email,password).then((userCredintial)=>{
                const user =userCredintial
                setUserIsVerified(true)
                return user
            }).catch((err)=>{
                setError(err.code)
                if(err.code === 'auth/multi-factor-auth-required' ){
                    setIsAskingCode(true)
                    setError(err)
               }
               
                // console.log(err.code)
                // setError(err.code)
                // console.log(err.message)
            });
             
            // 

            
           
            if(!error){
                 await updateDoc(
            doc(projectFirestore, "users", `${projectAuth.currentUser.uid}`),
            {
              online: true,
            })
            dispatch({ type: 'LOGIN', payload: res.user })
            navigate("/management/admin/");
            }
            if(!isCancelled){
              setIsPending(false)
              // setError(null)
          }
            // setError(null)
        }catch (err){
            setIsPending(false)
            if(!isCancelled){
                // setError(err)
            }
        }
    }
    useEffect(() => {
      
    
      return () => {
        setIsCancelled(true)
      }
    }, [])
    
    return {login,isPending,error,isAskingCode,sendCode,veriId,verifyLogin,userIsVerified}
}