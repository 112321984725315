import { useCollection } from '../../../hooks/useCollection';
import './ads.css'

export default function Ads() {
    const {documents:ads,isPending,error} = useCollection('ads','createdAt',['isPublished','==',true],null)

    return (
      <div className="ads-page">
       <div className='header-maker'>
      <h1>اعلانات</h1>
      </div>
      <div className="ads-box">
      {isPending && (<div  className='loading-style' ><h1>...جاري التحميل </h1></div>)}

      {!isPending && ads.map((ad,i) => (
        <div className="ad" key={i}>
        <h1 className='background-title ad-header'>{ad.adTitle}</h1>
        <p>{ad.adBody}</p>
        <h4 className='background-title'>{ad.adDate.toDate().toLocaleString("ar-IQ")}</h4>
   
        </div>
        ))}
      </div>
      </div>
    );
}
