import {  useState } from "react"
import { useAuthContext } from "../../../hooks/useAuthContext"
import { useStorage } from "../../../hooks/useStorage"



export default function CreateProduct() {
  

   const {uploadImageAndCreateDocument,imageUploadProgress,isPending}=useStorage('products','productsImage')

  //SECTION states
  const [productImage, setProductImage] = useState(null)
  const [productImageError, setProductImageError] = useState(null)
  const [productTitle,setProductTitle]=useState('')
  const [productDescription,setProductDescription]=useState('')
  const [productProcess,setProductProcess]=useState('')
  const [uses,setUses]=useState(['']);
  const [specs,setSpecs]=useState(['']);
  const {user}=useAuthContext()
  
  //SECTION Image Upload handler
  
    const handleFileChange = e => {
      setProductImage(null)
      let selected = e.target.files[0]
      if(!selected){
        setProductImageError("من فضلك اختر صورة ");
      return 
      }
      if(!selected.type.includes("image")){
        setProductImageError("من فضلك اختر صورة ");
        return 
      }
      if(selected.size > 4194304){
        setProductImageError("من فضلك اختر صورة بحجم اقل من 4 ميجابايت");
        return
      }
      setProductImageError(null)
      setProductImage(selected)

      
    }
 
    //SECTION Product Title
    const handleProductTitle=(e)=>{
      setProductTitle(e.target.value)
    }
    //SECTION Product Description
    const handleProductDescription=(e)=>{
      setProductDescription(e.target.value)
    }
    //SECTION Product Process
    const handleProductProcess=(e)=>{
      setProductProcess(e.target.value)
    }
    //SECTION Product Submit
    const handleProductSubmit=async(e)=>{
      e.preventDefault()
      const product={

        productTitle,
        productDescription,
        productProcess,
        uses,
        isPublished:false,
        specs,
        createdBy:user.displayName
      }
      uploadImageAndCreateDocument(productImage,product)
   
    }

    //SECTION Uses handler
    const handleAdd=()=>{
        const abc=[...uses,[]]
        setUses(abc)
    }
    const handleChange=(onChangeValue,i)=>{
     const inputdata=[...uses]
     inputdata[i]=onChangeValue.target.value;
     setUses(inputdata)
    }
    const handleDelete=(i)=>{
        const deleteUses=[...uses]
        deleteUses.splice(i,1)
        setUses(deleteUses)  
    }
    
    //SECTION Specs handlers
    const handleAddSpecs=()=>{
        const oldSpec=[...specs,[]]
        setSpecs(oldSpec)
    }
    const handleChangeSpecs=(onChangeValue,i)=>{
     const inputdata=[...specs]
     inputdata[i]=onChangeValue.target.value;
     setSpecs(inputdata)
    }
    const handleDeleteSpecs=(i)=>{
        const deleteSpec=[...specs]
        deleteSpec.splice(i,1)
        setSpecs(deleteSpec)  
    }

return (
  <div className="products-page">
    <form className="create-product" onSubmit={handleProductSubmit}>
      <div className="second-half">
        <label>الاستخدامات</label>
        {uses.map((data, i) => (
          <div key={i}>
            <label>
              <span>{`الاستخدام     ${i + 1}`}</span>
              <input
                required
                value={data}
                onChange={(e) => handleChange(e, i)}
              />
              <button
                className="btn"
                type="button"
                onClick={() => handleDelete(i)}
              >
                حذف
              </button>
            </label>
          </div>
        ))}
        <button className="btn" type="button" onClick={() => handleAdd()}>
          اضافة{" "}
        </button>
        <label>الخصائص </label>

        {specs.map((data, i) => (
          <div key={i}>
            <label>
              <span>{`خصيصة ${i + 1}`}</span>
              <input
                required
                value={data}
                onChange={(e) => handleChangeSpecs(e, i)}
              />
            </label>

            <button
              className="btn"
              type="button"
              onClick={() => handleDeleteSpecs(i)}
            >
              حذف
            </button>
            <br />
            <br />
          </div>
        ))}
        <button className="btn" type="button" onClick={() => handleAddSpecs()}>
          اضافة
        </button>
        <br />
        <br />
        <br />
        {isPending && (
          <>
            <button className="btn" type="submit" disabled>
              جاري التحميل
            </button>
            <div
              className="progress-bar"
              style={{
                width: imageUploadProgress + "%",
                height: `2em`,
                backgroundColor: `var(--primary-color)`,
                transition: `width 4s`,
              }}
            ></div>
          </>
        )}
        {!isPending && (
          <button className="btn" type="submit">
            انشاء
          </button>
        )}
      </div>
      <div className="first-half">
        <label>
          <span>صورة المنتج</span>
          <input type="file" required onChange={handleFileChange} />
        </label>
        {productImageError && <p className="error"> {productImageError}</p>}
        <label>
          <span>عنوان المنتج</span>
          <input onChange={handleProductTitle} type="text" required />
        </label>
        <label>
          <span>وصف المنتج </span>
          <textarea
            onChange={handleProductDescription}
            required
            style={{ resize: `none` }}
          ></textarea>
        </label>
        <label>
          <span>عملية التصنيع</span>
          <textarea
            onChange={handleProductProcess}
            required
            style={{ resize: `none` }}
          ></textarea>
        </label>
      </div>
    </form>
  </div>
);}


// ? TODO PRODUCT IMAGE 
// ? TODO PRODUCT TITLE
// ? TODO USES GOING TO BE AN ARRAY    
// ? TODO PRODUCTION PROCESS DESCRIPTION PARAGRAPH
// ? TODO TABLE OF SPECS ARRAY





