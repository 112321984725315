import { useState } from 'react'
import { RecaptchaVerifier,signInWithPhoneNumber } from 'firebase/auth'
import { projectAuth } from '../../../firebase/config'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useLogin } from '../../../hooks/useLogin'

import './Login.css'
import {  useNavigate } from 'react-router-dom'
export default function Login() {
  const [code, setCode] = useState('')
  const [email, setEmail] = useState('')
  const [isUserAskingCode, setIsUserAskingCode] = useState(null)
  const [password, setPassword] = useState('')
  const {login,isPending,error,isAskingCode,sendCode,verifyLogin,veriId,userIsVerified}=useLogin()
  const {user}=useAuthContext()
  const navigate=useNavigate()
  console.log(userIsVerified);
  
  const handleLogin = async e => {
    e.preventDefault()
    login(email, password)
  }
  
 
  
const handleMessageSend=async(e)=>{
e.preventDefault()
sendCode()
}

user && navigate("/management/admin/")

const handlePhoneLogin = e => {
  e.preventDefault()
  verifyLogin(code,veriId)  
}


  return (
    <div className='login-page'>
    <form  className="login-form" onSubmit={handleLogin}>
      <h3>Login</h3>
      <label>
        <span>البريد الالكتروني</span>
        <input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </label>
      <label>
        <span>كلمة المرور</span>
        <input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </label>
      {!isPending && !user  && <button className="btn" >دخول</button>}

      {isPending  && <button className="btn" disabled>جاري تسجيل الدخول...</button>}
      {error ==="auth/user-not-found" && (<>
      <p className="error">{"المستخدم غير موجود"}</p>
      </>)}
      {error ==="auth/wrong-password"  && (<>
      <p className="error">{"كلمة المرور خاطئة"}</p>
      </>)}
    </form>
   
      {isUserAskingCode && (<>
        <form id="phone-form" className='login-form' onSubmit={handlePhoneLogin}>
            <label>
              <span>رمز التأكيد</span>
              <input
                type="number"
                value={code}
                onChange={e => setCode(e.target.value)}
              />
            </label>
         <button className="btn">Verify</button> 
        </form>
      </>)}
        {(isAskingCode && !isUserAskingCode) &&<button className="btn" onClick={(e)=>{
          setIsUserAskingCode(true)
          handleMessageSend(e)
          }}>ارسال رسالة تأكيد </button>}
      <div id="recaptcha-container"></div>
   
    </div>
  )
}
 