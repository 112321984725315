import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useCollection } from '../../../hooks/useCollection'
import { useFireStore } from '../../../hooks/useFirestore'
import useUpdateNews from '../../../hooks/useUpdateNews'

import './News.css'


export default function NewsSummary({news}) {
    const [adminx, setAdminx] = useState("")

    //SECTION - NEWS EDIT 
    const {deleteDocument,response,updatenews}=useFireStore("news")
    const {documents}=useCollection("users")
    const [titleEdit, setTitleEdit] = useState('')
    const [description, setDescription] = useState('')
    const [newsDate, setNewsDate] = useState('')
    const [isEditing, setIsEditing] = useState(false)
    
    //SECTION Image Editiing
    const [newsImageError, setNewsImageError] = useState(null)
    const [newsImage, setNewsImage] = useState(null)
    const [isEditingImage, setIsEditingImage] = useState(false)
    const [imgReference,setImageReference]=useState(false)
    
    const {deleteNewsImage,uploadNewNewsImage} =useUpdateNews()


    //CHECKING ADMIN
    
    const {user}=useAuthContext()
    useEffect(() => {
    if(documents){
        const admin=documents.filter((document)=>{
            let admin
            if(document.admin === true ){
                if(document.id === user.uid){
                  setAdminx(document.admin)
                }
             return admin=document
            }
            return admin
        })
    }
    
      return () => {
      
      }
    }, [documents,user.uid])
    

    //SECTION EDIT POST INFO
    const handleCancel =()=>{
        setIsEditing(false)
        setTitleEdit('')
        setDescription('')
        setNewsDate('')

    }

    const handleEdit=()=>{
        setIsEditing(true)
        setTitleEdit(news.title)
        setDescription(news.details)
        setNewsDate(news.date.toDate().toISOString().split('T')[0])
    }
    
    const handleUnPublish=()=>{
     updatenews(news.id,{isPublished:false})
    }

    const handleUpdate=(e)=>{
     updatenews(news.id,{isPublished:true})
    }
    const handleEditComplete=(e)=>{
        e.preventDefault()
        setIsEditing(!isEditing)
        updatenews(news.id,{title:titleEdit,details:description,date:new Date(newsDate)})
    }
    const navigate=useNavigate()
    const handleDelete= ()=>{
         
         deleteDocument(news.id)
         deleteNewsImage(news.imgRef)
         if (!response.error) {
            navigate("/management/admin/")
        }
    }
    
    //SECTION IMAGE UPDATE RELATED
    const handleFileChange = e => {

        setNewsImage(null)
        let selected = e.target.files[0]
        if(!selected){
          setNewsImageError(" من فضلك اختر صورة");
        return 
        }
        if(!selected.type.includes("image")){
          setNewsImageError("من فضلك اختر صورة");
          return 
        }
        if(selected.size > 4194304){
          setNewsImageError(" من فضلك اختر صورة بحجم اقل من 4 ميجابايت");
          return
        }
        setNewsImageError(null)
        setNewsImage(selected)
        setImageReference(news.imgRef)
      }
      
      const handleCancelImageEdit=()=>{
        setIsEditingImage(false)
        setNewsImage(null)
        setImageReference(false)
        setNewsImageError(null)
        
      }

      const handleSaveNewImage=()=>{
    const rando=Math.floor(Math.random()*152634)
        
        setIsEditingImage(false)
        if(newsImage !== null){
          uploadNewNewsImage(news.id,"news",newsImage,imgReference,`newsImages/${rando}${newsImage.name}`)
          setIsEditingImage(false)
          setImageReference(false)
          setNewsImage(null)
        }
        setIsEditingImage(false)
      }
      const handleEditImage=()=>{
       setIsEditingImage(true)
       setImageReference(news.imgRef)
      }

   
    
    return (
      <div className="news-sum-page" >
        {!isEditing && (
          <>
            <div className="news-summary">
              <div className="news-sum-image">
                <h2 className="news-title">{news.title}</h2>
                <p> نشره {news.createdBy.displayName}</p>
                <p className="news-date">
                  {`تاريخ الخبر   ${news.date
                    .toDate()
                    .toLocaleDateString("ar-IQ")}`}
                </p>
                {}
                {!isEditingImage && (
                  <>
                    <img
                      src={news.imageURL}
                      style={{ width: `100%`, height: `25rem`,borderRadius:`5px` }}
                      alt="NewsImage"
                    />
                    <div style={{marginBottom:`2.5rem`}}>
                      <button
                        onClick={handleEditImage}
                        className="btn"
                        type="button"
                      >
                        تعديل صورة الخبر
                      </button>
                    </div>
                  </>
                )}
                {isEditingImage && (
                  <>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ width: `100%`, height: `230px` }}
                    />
                    {imgReference !== false && newsImage ? (
                      <button
                        className="btn"
                        type="button"
                        onClick={handleSaveNewImage}
                      >
                        حفظ صورة جديدة
                      </button>
                    ) : null}

                    <button
                      className="btn"
                      onClick={handleCancelImageEdit}
                      type="button"
                    >
                      الغاء{" "}
                    </button>
                    {newsImageError && (
                      <p className="error">{newsImageError}</p>
                    )}
                  </>
                )}
              </div>
              <div className="news-sum-text" >
                <p className="news-details" style={{direction:`rtl`,textAlign:`right`}} >{news.details}</p>
              </div>
            
             

                <div
                 
                  className="buttons"
                >
                  <button className="btn" onClick={handleEdit}>
                    تعديل الخبر
                  </button>
                  {adminx && !news.isPublished && (
                    <button
                      className="btn"
                      style={{ backgroundColor: `#980048`, color: `white` }}
                      type="button"
                      onClick={handleUpdate}
                    >
                      نشر
                    </button>
                  )}
                  {adminx && news.isPublished && (
                    <button
                      className="btn"
                      style={{ backgroundColor: `#980048`, color: `white` }}
                      type="button"
                      onClick={handleUnPublish}
                    >
                      عدم النشر
                    </button>
                  )}
                  {adminx && (
                    <button
                      className="btn"
                      style={{ backgroundColor: `#980048`, color: `white` }}
                      type="button"
                      onClick={handleDelete}
                    >
                      حذف
                    </button>
                  )}
                </div>
              </div>
          </>
        )}
        {isEditing && (
          <>
            <div
              style={{ fontSize: "1.2em", transition: `all 1.5s` }}
              className="news-summary"
            >
              <form onSubmit={handleEditComplete}>
                <label>
                  <span>عنوان</span>
                  <input
                    required
                    type="text"
                    name="name"
                    value={titleEdit}
                    onChange={(e) => setTitleEdit(e.target.value)}
                  />
                </label>
                <label>
                  <span>تعديل التفاصيل</span>

                  <textarea
                    required
                    style={{ resize: `none` }}
                    type="text"
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </label>
                <label>
                  <span>تعديل التاريخ</span>
                  <input
                    required
                    type="date"
                    name="dueDate"
                    value={newsDate}
                    onChange={(e) =>setNewsDate(e.target.value)}
                  />
                </label>
                <div
                  style={{ display: `flex`, gap: `2em` }}
                  className="buttons"
                >
                  <button
                    className="btn"
                    style={{ backgroundColor: `#980048`, color: `white` }}
                    onClick={handleCancel}
                    type="button"
                  >
                    الغاء التعديلات
                  </button>
                  <button className="btn" type="submit">
                    حفظ التعديلات
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    );
}
