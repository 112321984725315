import { Navigate, Outlet, Route } from "react-router-dom";

import React from 'react'
import { useAuthContext } from "../hooks/useAuthContext";
import NotFound from "./public-pages/notFoundPage/NotFound";
import Sidebar from "../components/Sidebar/Sidebar";

export default  function PrivateRoutes () {
    const {user,authIsReady}=  useAuthContext()
     
        if(authIsReady){
          return (<div className="container">
            <Sidebar/>
            <Outlet/>
          </div>)
        }else{
          return (
            <NotFound/> 
          )
          
        }
       
      
    }
   
