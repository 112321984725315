import NewsList from '../../../components/ProjectList/NewsList'
import { useCollection } from '../../../hooks/useCollection'
import './Dashboard.css'

import { useAuthContext } from "../../../hooks/useAuthContext"



export default function Dashborad() {
  const {documents:news,error}=useCollection("news")
  const {user} = useAuthContext()
  
  const loadingStyle={
    position:'absolute',
    top:'45%',
    left:'55%',
    fontSize:'1.3rem'

  }

  
  
  return (
    <div className='news-dashboard' >
    {news === null && <div style={loadingStyle}>جاري التحميل ...</div>}
    {error && <p className='error'>{error}</p>}
    <div>
    {news && <NewsList news={news}/>}
    </div>
</div>
  
  )
}
