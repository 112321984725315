import React from "react";
import svgRect from '../products/prod-page-assets/path-61.svg'
import iraqQuality from './iraqi-quality-certificate.png'
import iso9001 from './iso-9001-certificate.png'
import {useCollection} from '../../../hooks/useCollection'

import './AboutPage.css'


export default function AboutPage() {
    

  const loadingStyle={
    display:'block',
    top:'50vh',
    left:'50vw',
    fontSize:'1.3rem',
    minHeight:'100vh'
  }

  const {documents:aboutPage,isPending,error}=useCollection('publicPages')


  return (
    <div className="about-page" >
      {isPending && !error && (<div style={loadingStyle}><h1>...جاري التحميل </h1></div>)}

     {!isPending && aboutPage && (<>

      <div className="about-page-content">
        <section className="about-factory">
          <h1 className="about-header">معمل النورة في كربلاء</h1>
          <p>{aboutPage[0].aboutFactory}</p>
          <img src="" alt="" />
        </section>


        <section className="production-power">
          <h1 className="about-header">الطاقة الانتاجية</h1>
          <p>{aboutPage[0].productionPower}</p>
        </section>

          
        <section className="certificates">
          <h1 className="certificates-header about-header">شهادات الجودة</h1>
          
          <div className="certificates-wrapper" >

           <div className="first-certificate">
            <div className="first-certificate-img">
            
            <img src={aboutPage[0].imageURL1} alt="" />
            </div>
            
            <div className="first-certificate-text">
            <h1 className="about-header">{aboutPage[0].secondCertificateTitle}</h1>
            <p>{aboutPage[0].firstCertificateDescription}</p>
            </div>

           </div>
          
           <div className="second-certificate">
             
             <div className="second-certificate-img">
             <img src={aboutPage[0].imageURL2}  alt="" />
             </div>
             
             <div className="second-certificate-text">
              <h1 className="about-header">{aboutPage[0].firstCertificateTitle}</h1>
              <p>{aboutPage[0].secondCertificateDescription}</p>
             </div>

           </div>
           </div>

        </section>

      </div>

      </>)}

    </div>
  );
}
