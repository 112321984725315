import { initializeApp } from "firebase/app";
//FireStore
import {limit, Timestamp,setDoc,deleteDoc,doc,getFirestore,addDoc,serverTimestamp,query, where,collection,onSnapshot,orderBy,updateDoc,getDoc,arrayUnion} from "firebase/firestore";
//Storage
import { getStorage, ref,uploadBytesResumable ,getDownloadURL,deleteObject} from "firebase/storage";

// import from Auth 
import {  getMultiFactorResolver,multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator,
  RecaptchaVerifier,applyActionCode,sendEmailVerification,getAuth,signInWithEmailAndPassword,onAuthStateChanged,
 } from "firebase/auth";



const firebaseConfig = {
  apiKey: "AIzaSyCMwT1Wc2-B1TkO7ypZcQMREqd31FmS7Cw",
  authDomain: "karbalalimefactory.firebaseapp.com",
  projectId: "karbalalimefactory",
  storageBucket: "karbalalimefactory.appspot.com",
  messagingSenderId: "31723424893",
  appId: "1:31723424893:web:527d6c7b3a13568b853514",
  measurementId: "G-2DTH3SBDWC"
};
const app = initializeApp(firebaseConfig);

//Firestore database
const projectFirestore = getFirestore(app);
const timeStamp = serverTimestamp()


//storage 
const projectStorage = getStorage();

const projectAuth = getAuth(app);




export { multiFactor,limit, PhoneAuthProvider, PhoneMultiFactorGenerator,
  RecaptchaVerifier,applyActionCode,sendEmailVerification,setDoc,deleteDoc,doc,getFirestore,addDoc,serverTimestamp,query, where,collection,onSnapshot,orderBy,updateDoc,projectAuth,timeStamp,projectStorage,projectFirestore,signInWithEmailAndPassword,onAuthStateChanged,ref,getDownloadURL,uploadBytesResumable,getDoc,arrayUnion,Timestamp, getMultiFactorResolver,deleteObject}