import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client'
import {AuthContextProvider} from "./context/AuthContext"
import './index.css';
import App from './App';
// ReactDOM.render(
//   <React.StrictMode>
//     <AuthContextProvider>
//     <App />
//     </AuthContextProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container);
root.render(

    <AuthContextProvider >
    <App />
    </AuthContextProvider>

);
