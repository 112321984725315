import React from 'react'
import { Link,Router,Route } from "react-router-dom"
import { useCollection } from '../../../hooks/useCollection'
import  './Products.css'
export default function ProductsPage() {
  const {documents}=useCollection('products')
  const loadingStyle={
    position:'absolute',
    top:'45%',
    left:'55%',
    fontSize:'1.3rem'

  }
  const noNewsStyle = {
    position: "absolute",
    top: "50%",
    left: "55%",
  };
  return (<>
    
    <div >
      
        {documents === null && <div style={loadingStyle}>جاري التحميل ...</div>}
        {documents.length === 0 && <p style={noNewsStyle}>لا يوجد منتجات </p>}
        <div className='products-grid'>
        {documents && 

          documents.map((prod,index) => {
            return (

              <Link key={prod.id}  to={`/management/admin/products/${prod.id}`} >
                 <h2>{prod.productTitle}</h2>
              <img
             
                  src={prod.imageURL}
                  alt=""
                />
                <div className='product-description'>

                <h3>وصف المنتج</h3>
  
                <p>{`${prod.productDescription }`}</p>
               <h3>عملية التصنيع</h3>
                <p>{`${prod.productProcess }`}</p>
         
                  <h3>الاستخدامات</h3>
            
                <div >
                  {prod.uses &&
                  <ul style={{ display: `grid` }}>
                    {prod.uses.map((use, i) => {

                    if(i<3){
                            return <li key={i}>{`${use}`}</li>
                    }
                   
                   
                        
                   
                    })}
                  </ul> }
                  
                  <h3>الخصائص</h3>
                  {prod.specs && 
                    <ul style={{ display: `grid` }}>
                    {prod.specs.map((spec, i) => {

                  
                      if(i<3){
                            return <li key={i}>{`${spec}`}</li>
                    }
                       
                      
                    }
                    )}
                  </ul> 
                  }
                </div>
                  
              </div>
              
                </Link>
                
            
            );
          })          }
          </div>
     
    </div>
 </>
  );
}
