import { useEffect, useState } from "react"
import {createUserWithEmailAndPassword , updateProfile} from "firebase/auth";
import {sendEmailVerification,applyActionCode,setDoc,doc,projectAuth,timeStamp,projectStorage,projectFirestore,ref,uploadBytesResumable,getDownloadURL,addDoc,collection} from "../firebase/config"

import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";



export const useSignUp =()=> {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const {dispatch} = useAuthContext()
    const navigate =useNavigate()
    const signUp= async (email,password,displayName,isAdmin)=>{
        setError(null)
        setIsPending(true)

        try {
            //signUp user
            const res = await createUserWithEmailAndPassword(
              projectAuth,
              email,
              password
            ).then(async (res)=>{
        
              const actionCodeSettings = {
                url: 'https://karbalalimefactory.firebaseapp.com/__/auth/action'
              };
                // console.log(res);
                // const uploadPath = ref(projectStorage,`thumbnails/${res.user.uid}/${thumbnail.name}`)
                // const img = await uploadBytesResumable(uploadPath,thumbnail)
                // const imgURL = await getDownloadURL(img.ref).then((downloadURL) => {
                //     return downloadURL
                //   });
    
                //create a user document
                
                //add Display name to user 
                await updateProfile(projectAuth.currentUser,{displayName})
    
                
                await setDoc(doc(projectFirestore,"users",`${res.user.uid}`),{
                     online:true,
                     displayName,
                     admin:isAdmin,                     
                     createdAt:timeStamp
                 })
            sendEmailVerification(res.user, actionCodeSettings);
            // Obtain code from the user.
         
            setIsPending(false)
            setError(null)
            navigate("/management/admin/")
            // console.log(res.user);
            // console.log(res.user.emailVerified);
            // console.log(res.user.uid);
            // console.log(res.user.displayName);
            // console.log(res.user.photoURL);
            // console.log(res.user.email);
            // console.log(res.user.phoneNumber);
            // console.log(res.user.isAnonymous);
            // console.log(res.user.metadata);
            // console.log(res.user.providerData);
            // console.log(res.user.tenantId);
            // console.log(res.user.multiFactor);
            // console.log(res.user.refreshToken);
            // console.log(res.user.getIdToken);
            // console.log(res.user.getIdTokenResult);

          }
           ).catch((signUpError)=>{
            setIsPending(false)
            setError(signUpError)
          })

          
           
       
            

            
            //upload image to storage
           
            
        }catch (err){
            setError(err.message)
            setIsPending(false)
        }
        
    }
    useEffect(() => {
      
      return () => {
        setIsPending(false)
        setError(null)
      }
    }, [])
    

    return {signUp,isPending,error}
}
