
import { useEffect, useRef, useState } from 'react';
import {useCollection} from '../../../hooks/useCollection';
import Hero from './homeComps/Hero/Hero';
import HomeNews from './homeComps/HomeNews/HomeNews';
import KLFHero from './homeComps/KLFHero/KLFHero';
import ProductsSection from './homeComps/ProductsSection/ProductsSection';
import './HomePage.css'

import CertificatesSection from './homeComps/CertificatesSection/CertificatesSection'


const loadingStyles={
  width:'40rem',
  height:'25rem',
  textAlign:'center',
  fontSize:'2rem',
  display:'grid',
  alignItems:'center',
}
const goUpBtnStyle={
  position:'fixed',
  bottom:'2rem',
  right:'2rem',
  width:'3rem',
  height:'3rem',
  border:"solid 1px var(--hover-color)",
  borderRadius:'50%',
  backgroundColor:'#dab565',
  color:'var(--hover-color)',
  fontSize:'2rem',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  cursor:'pointer',
  zIndex:'1000',
}

export default function HomePage() {
  const { documents:news, error, isPending } = useCollection('news','date',["isPublished", "==" , true ],3);
 

  const [showGoUpBtn, setShowGoUpBtn] = useState(false)
  const goUpBtnRef=useRef(null)

  useEffect(() => {
    window.addEventListener('scroll',()=>{
      if(window.scrollY>100){
        setShowGoUpBtn(true)
      }else{
        setShowGoUpBtn(false)
      }
    })
    return () => {
      window.removeEventListener('scroll',()=>{})
    }
  }, [])
 

  return (
    <div >
    <Hero/>
    <KLFHero/>
    <HomeNews   />
    <ProductsSection/>

    <CertificatesSection/> 
        {/* 
    {/* <a href="#top"><button ref={goUpBtnRef}  className={showGoUpBtn ? 'goUpbutton':'hide'} >↑</button></a> */}

    </div>
    
  )
} 
