//main imports
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from "react-icons/fa";
import { useRef } from "react";

//styles
import './PublicNav.css';

//assets
import logo from '../../../assets/images/logo.png'
import { useState } from 'react';
 

export default function PublicNavBar() {
 const [color,setColor]=useState(false)
 const changColor=()=>{
  if (window.scrollY > 90){
    setColor(true)
  }else{
    setColor(false)
  }
 }
 const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"show_nav"
		);
	};
  /*
  <header>
        
        <div className="logo">
        <img src={logo} alt="logo" className="logoImg"/>
        </div>
        <div>
        <input type="checkbox" id="nav-toggle" className={"nav-toggle" }/>
        <nav>
            <ul>
            <NavLink to='/' className="main-nav-link">الرئيسية</NavLink>
          <NavLink to='/news' className="main-nav-link">الاخبار</NavLink>
          <NavLink to='/products' className="main-nav-link">المنتجات</NavLink>
          <NavLink to='/about' className="main-nav-link">حول</NavLink>
            </ul>
        </nav>
        
        <label htmlFor="nav-toggle" className="nav-toggle-label">
            <span></span>
        </label>
        </div>
        </header>
  */ 
  window.addEventListener('scroll', changColor)
  return (
    <>
      <header
        className="public-phone-header"
        style={
          color
            ? {
                backgroundColor: `#2c36398a`,
                transition: `background-color 400ms`,
              }
            : {
                backgroundColor: `transparent`,
                transition: `background-color 400ms`,
              }
        }
      >
        <div className="phone-logo">
          <img src={logo} alt="logo" className="phone-logo-img" />
        </div>
        <div className="phone-navigation">
          <nav className="phone-navigation-nav" ref={navRef}>
            <NavLink to="/" className="main-nav-link">
              الرئيسية
            </NavLink>
            <NavLink to="/news" className="main-nav-link">
              الاخبار
            </NavLink>
            <NavLink to="/products" className="main-nav-link">
              المنتجات
            </NavLink>
            <NavLink to="/ads" className="main-nav-link">
            اعلانات
            </NavLink>
            <NavLink to="/about" className="main-nav-link">
              حول
            </NavLink>

            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
              <FaTimes />
            </button> 
          </nav>

          <button className="nav-btn" onClick={showNavbar}>
            <FaBars />
          </button>
        </div>
      </header>
    </>
  );
}
