import React from "react";
import './Footer.css'

import facebook from  './facebook.png'
import whatsApp from "./whatsapp.png"
import gmail from "./gmail.png"
import viber from './viber.png';

import factory from "./Factory.svg" 
const Footer = () => {


  return (
    <>
      <div id="contactus" >
        <div className=" footer-divider background-title">
          <h4>تواصل معنا</h4>
        </div>
      </div>
      <footer className="footer">
        <div className="footer1"></div>
        <div className="directions-social">
          <div className="getdirections ">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2910.448815681239!2d43.90387977670762!3d32.5636771146656!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15595c57a2411577%3A0x52f2a86efe51f7d3!2z2YXYudmF2YQg2KfZhNmG2YjYsdip!5e0!3m2!1sar!2sru!4v1675151116055!5m2!1sar!2sru"
              width={{ width: `90vw` }}
              height="450"
              style={{ border: `solid #4F3C45 2px`, borderRadius: `15px` }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <p>كربلاء المقدسة طريق الحج البري , مقابل مديرية المرور</p>
            <a href="https://www.google.com/maps/dir//HW73%2BFC5+%D9%85%D8%B9%D9%85%D9%84+%D8%A7%D9%84%D9%86%D9%88%D8%B1%D8%A9,+%D8%A7%D9%85%D8%A7%D9%85+%D9%85%D8%AF%D9%8A%D8%B1%D9%8A%D8%A9+%D8%A7%D9%84%D9%85%D8%B1%D9%88%D8%B1+%D8%A7%D9%84%D8%B9%D8%A7%D9%85%D8%A9%D8%8C+%D8%B7%D8%B1%D9%8A%D9%82+%D8%A7%D9%84%D8%AD%D8%AC+%D8%A7%D9%84%D8%A8%D8%B1%D9%8A%D8%8C+%D9%83%D8%B1%D8%A8%D9%84%D8%A7%D8%A1%D8%8C+%D8%A7%D9%84%D8%B9%D8%B1%D8%A7%D9%82%E2%80%AD/@32.5635567,43.9034019,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x15595c57a2411577:0x52f2a86efe51f7d3!2m2!1d43.9036028!2d32.5636415?hl=ar">
              <button className="get-directions-button" >
                الحصول على الاتجاهات
              </button>
            </a>
          </div>
          <div className="social-media ">
            <div className="contact-us">
              <div className="social-media-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=100088585352253"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  <img className="facebook-icon" alt="" src={facebook} />
                </a>

                <a
                  href="viber://chat?number=%2B9647713009500"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  {" "}
                  <img className="viber-icon" alt="" src={viber} />
                </a>

                <a
                  href="https://wa.me/+9647713009500"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  {" "}
                  <img className="whatsapp-icon" alt="" src={whatsApp} />
                </a>

                <a
                  href="mailto: alnoorainfo@gmail.com" 
                  rel="noreferrer"
                  target={"_blank"}
                >
                  {" "}
                  <img className="gmail-icon" alt="" src={gmail} />
                </a>
              </div>
              <div className="email-number">
                <br />
                <br />
                        <span>
                          <a href="tel:+9647713009500"> +9647713009500</a>
                        </span>
                    
                        <span>
                          <a
                            href="mailto: alnoorainfo@gmail.com" 
                            rel="noreferrer"
                            target={"_blank"}
                          >
                            {" "}
                            alnoorainfo@gmail.com
                          </a>
                        </span>
              </div>
            </div>
          </div>
        </div>
        <div className="end-footer">
          <div className="end-logo">
            <img className="factory-icon" alt="" src={factory} />
            <p> جميع الحقوق محفوظة لمعمل النورة في كربلاء المقدسة </p>
            <h4>
              <span>&copy;</span> 2023{" "}
            </h4>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
