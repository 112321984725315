import { useCollection } from "../../../hooks/useCollection"
import './ManageAds.css'
import AdEdit from "./AdEdit";
import CreateAd from "../CreateAds/CreateAd";
import { useState } from "react";
export default function ManageAds() {

  const {documents:ads,isPending,error} =useCollection('ads', 'adDate')
  const [isCreatingAd, setIsCreatingAd] = useState(false)
  console.log(ads,isPending,error);
  return (
    <div className="manage-ads-page">

    {isPending && <div className="loading">
      جاري التحميل...
    </div>}
   
    <h1>الاعلانات</h1>
    {!isCreatingAd && 
    <button className="btn" style={{position:`absolute`,right:`5%`}} onClick={()=>setIsCreatingAd(!isCreatingAd)} > اضافة اعلان</button>    
    }
    {!isPending && ads.length===0 && <div className="loading" > لا يوجد اعلانات </div>}
    {isCreatingAd && <CreateAd toggleCreate={setIsCreatingAd} toggleState={isCreatingAd}/>}
      <div className="manage-ads-list" >
    {!isCreatingAd && ads.length!==0 && ads.map((ad,i)=>(
         <AdEdit key={i} adTitle={ad.adTitle} adBody={ad.adBody} adDate={ad.adDate} adId={ad.id} status={ad.isPublished} />
    )) }
    
        </div>
    
    </div>
  )
}
