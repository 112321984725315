import React from 'react'
import {useCollection} from '../../../hooks/useCollection'

import './NewsPage.css'
import svgRect from '../products/prod-page-assets/path-61.svg'
import ReadMore from '../../../components/ReadMore/ReadMore'
export default function NewsPage() {
  const { documents:news, error, isPending } = useCollection('news','date',["isPublished", "==" , true ],null);

   

return (
     
      <div className="news-page" style={{marginTop:`10rem`}}>

    {error && <p>هناك خلل في تحميل الاخبار</p>}    

    {isPending && (<div  className='loading-style' ><h1>...جاري التحميل </h1></div>)}

    <div className='header-maker '>
      <h1>الاخبار</h1>
      </div>
    {news && (<>
    
    {news.map((newx,index)=>(<div key={index}>

     {newx.isPublished &&
     
     <div className="news-page-box  "  key={index}>
        
     <div className="news-page-image-box">
       <img src={newx.imageURL} alt="" />
     </div>
     

     <div className="news-page-text-box">

     <div className='news-page-news-title background-title'>
       <h1 >{newx.title}</h1>
     </div>

    
        {newx.details.length > 300 
        ?(
          <ReadMore limit={300}>
          {newx.details}
         </ReadMore>
         )
         :(
          `${newx.details}`
          )}
          
       <span className='background-title' >{newx.date.toDate().toLocaleDateString('ar-IQ')}</span>
     </div>
     

     </div> }
    
     {/* <svg xmlns="http://www.w3.org/2000/svg" width="1366" height="59.181" viewBox="0 0 1366 59.181">
       <path id="Path_84" data-name="Path 84" d="M0,40.819,1366,66.268V100H0Z" transform="translate(0 -40.819)" fill="#dab565"/>
     </svg> */}
    </div>
))}

       

</>)}

      </div>
     )
}
