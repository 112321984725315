import { useReducer,useEffect, useState } from "react";
import {  arrayUnion,doc,projectFirestore, collection,addDoc ,setDoc, timeStamp ,deleteDoc,updateDoc} from "../firebase/config";

export const initialState={
    document:null,
    isPending:false,
    error:null,
    success:null
}

export const firestoreReducer = (state,action) => {

    switch (action.type) {
        case 'IS_PENDING':
      return { isPending: true, document: null, success: false, error: null }
    case 'ADDED_DOCUMENT':
      return { isPending: false, document: action.payload, success: true, error: null }
    case 'DELETED_DOCUMENT':
      return { isPending: false, document: null, success: true, error: null }
    case 'ERROR':
      return { isPending: false, document: null, success: false, error: action.payload }
    case "UPDATED_DOCUMENT":
      return { isPending: false, document: action.payload, success: true,  error: null }
    default:
      return state
    }
}


export const useFireStore = (coll) => {
    const [response, dispatch] = useReducer(firestoreReducer,initialState)
    const [isCancelled, setIsCancelled] = useState(false)
    const collRef=collection(projectFirestore,coll);


    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
          dispatch(action)
        }
      }


    //set Document function overWrites existing obj or creates new Object
    const setDocument=async (data,page)=>{
      
      const aboutPageRef=doc(projectFirestore,coll,page)
      await setDoc( aboutPageRef,{...data},{merge:true})

    }
    
    //add Document 
    const addDocument = async (data,imageURL,imgRef) => {
        dispatchIfNotCancelled({type:'IS_PENDING'})
         
        try {  
               
               const addedDocument = await addDoc(collRef, {
                ...data,
                imageURL,
                imgRef:imgRef,
                createdAt: timeStamp
            });
            dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument })
            }
        catch (err) {
            console.log(err);
            dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
        }
    }
    const addDocWithoutImage = async (data) => {
      dispatchIfNotCancelled({type:'IS_PENDING'})
       
      try {  
             
             const addedDocument = await addDoc(collRef, {
              ...data,
              createdAt: timeStamp
          });
          dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument })
          }
      catch (err) {
          console.log(err);
          dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
      }
  }

  const deleteDocument = async (id) => {
      
        dispatchIfNotCancelled({type:'IS_PENDING'})
        try {
             await deleteDoc(doc(projectFirestore,coll,id));
             dispatchIfNotCancelled({ type: 'DELETED_DOCUMENT' })
        } catch (error) {
            dispatchIfNotCancelled({ type: 'ERROR', payload: 'could not delete' }) 
        }
  }


  const updatenews= async (id,updates)=>{
    console.log(id,updates);
    const newsToUpdate=doc(projectFirestore,coll,id)
    dispatch({type:'IS_PENDING'})
    try {
        const updatedNews = await updateDoc(newsToUpdate, {
            ...updates,
            updatedAt: timeStamp
        });
        dispatchIfNotCancelled({ type: 'UPDATED_DOCUMENT', payload: updatedNews })
    } 
    catch (err) {
        dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
    } 

  }
  const updateDocument = async (id,updates) => {
    
    const documentUpdate=doc(projectFirestore,coll,id)

    dispatchIfNotCancelled({type:'IS_PENDING'})
    try {
    const updatedDocuemnt =  await updateDoc(documentUpdate,{
        comments:arrayUnion(updates)
    });

    return updatedDocuemnt
} catch (err) {
console.log(err);
}
  }
    useEffect(()=>{
        return () => setIsCancelled(true)
    })
    return {addDocument,deleteDocument,response,updateDocument,updatenews,setDocument,addDocWithoutImage}
}