
import { useEffect, useState, useRef } from "react";
import { projectFirestore, collection, onSnapshot, orderBy, query, where, limit } from "../firebase/config";

export const useCollection = (coll, orderByField, whereClause, limitValue) => {
  const [isPending, setIsPending] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null);

  const queryRef = useRef({ orderBy: orderByField, where: whereClause, limit: limitValue }).current;
  
  useEffect(() => {
    let q = collection(projectFirestore, coll);
    if (queryRef.where) {
      q = query(q, where(...queryRef.where));
    }
    if (queryRef.orderBy) {
      q = query(q, orderBy(queryRef.orderBy, "desc"));
    }
    if (queryRef.limit) {
      q = query(q, limit(queryRef.limit));
    }

    const unsub = onSnapshot(q, (snap) => {
      let documents = [];
      snap.forEach((doc) => {
        documents.push({ ...doc.data(), id: doc.id });
      });

      setIsPending(false);
      setDocuments(documents);
      setError(null);
    }, (err) => {
      setError(err.message);
      setIsPending(false);
    });

    return () => unsub();
  }, [coll, queryRef]);

  return { documents, error, isPending };
};