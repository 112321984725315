import React from "react";
import {useCollection} from '../../../../../hooks/useCollection'



export default function AboutPage() {
    

  const loadingStyle={
    position:'absolute',
    top:'50vh',
    left:'50vw',
    fontSize:'1.3rem'

  }
const {documents:aboutPage,isPending,error}=useCollection("publicPages")

  return (<div style={{marginTop:`5rem`,position:`relative`}}>

 
      {isPending && !error && (<div style={loadingStyle}>جاري التحميل </div>)}

       {!isPending && aboutPage && (<div className="certificates-home">

     
 
          
          <section className="certificates">
          <h1 className="certificates-header home-products-header" style={{marginInline:`auto`}}>شهادات الجودة</h1>
          
          <div className="certificates-wrapper" >

           <div className="first-certificate">
            <div className="first-certificate-img">
            
            <img src={aboutPage[0].imageURL1} alt="" />
            </div>
            
            <div className="first-certificate-text">
            {/* <h1 className="about-header">{aboutPage[0].secondCertificateTitle}</h1> */}
            </div>

           </div>
          
           <div className="second-certificate">
             
             <div className="second-certificate-img">
             <img src={aboutPage[0].imageURL2}  alt="" />
             </div>
             
             <div className="second-certificate-text">
              {/* <h1 className="about-header " >{aboutPage[0].firstCertificateTitle}</h1>11 */}
             </div>

           </div>
           </div>

        </section>


      </div>)}
      </div>


  );
}
