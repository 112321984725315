import { useCollection } from "../../../../../hooks/useCollection"
import './HomeNews.css'
import { useCallback, useEffect, useState } from "react"
import ImageSlider from "./slider"
import ReadMore from "../../../../../components/ReadMore/ReadMore"

export default function HomeNews() {
    const [newsIndex,setNewsIndex]=useState(0)
    const [newsIndex2,setNewsIndex2]=useState(0)
    

    const { documents:news, error, isPending } = useCollection('news','date',["isPublished", "==" , true ],3);

    
const goBack=()=>{
    if(newsIndex>0){
        setNewsIndex(newsIndex-1)
    }else{
        setNewsIndex(news.length-1)
    }
}

 const goNext= ()=>{
    console.log("X");
    if(newsIndex<news.length-1){
        setNewsIndex(newsIndex+1)
    }else{
        setNewsIndex(0)
    }
}
const reArrange=(array,from,to)=>{
    // eslint-disable-next-line no-extend-native
    Array.prototype.move = function (from, to) {
        this.splice(to, 0, this.splice(from, 1)[0])
      };
      var ar = array;
        ar.move(from,to)
      return ar
 }

 
  return (<>

    <div className="home-news-slider">

       
        <div className="home-news-title">
        <h1 >اخر الاخبار</h1>
        </div>
        {error && <p className="error">حدث خطأ في تحميل الاخبار</p>}
        {isPending && !error && <div className="loading-style">...جاري التحميل </div>}

        {news && news.map((news,index)=>(
            <div className="home-news-card" key={index}>
            <div className="home-news-image">
                <img src={news.imageURL} alt="" />
            </div>
            <div className="home-news-text">
                <div className="home-news-text-title">
                <h1>{news.title} </h1>
                </div>
                <div className="home-news-text-paragraph">
                {news.details.length > 200 ? (
                          <ReadMore limit={200}>
                            {news.details}
                          </ReadMore>
                        ) : (
                          `${news.details}`
                        )}
                </div>
            <span className='background-title' >{news.date.toDate().toLocaleDateString('ar-IQ')}</span>
                
            </div>
        </div>
        ))}
       
       
    </div>
    </>
  
    )
}
