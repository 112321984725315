import {useCollection }from '../../../hooks/useCollection'
import ReadMore from '../../../components/ReadMore/ReadMore'

import './ProductsPage.css'

import svgRect from './prod-page-assets/path-61.svg'

export default function ProductsPage() {
   
  const loadingStyle={
    position:'absolute',
    top:'50vh',
    left:'50vw',
    fontSize:'1.3rem'

  }

  const {documents:products,isPending,error}=useCollection('products')
  
  return (
    <div>
      <div className="products-public-page">
      <div id='products' className='header-maker'>
      {/* <img className="path-51-icon" alt="" src={svgRect} /> */}
      <h1>المنتجات</h1>
      </div>
      {error && <p className='error'>حدث خطأ في تحميل المنتجات</p>}
      {isPending && !error && (<div style={loadingStyle}><h1>...جاري التحميل </h1> </div>)}

       {products && products.map((prod,index)=>{
        if(prod.isPublished){
          return (
            <div className="product-card" key={index}>
              <div className="prod-header-image">
                <img src={prod.imageURL} alt="" />
              </div>
              <div className='prod-text-wrapper'>
              <div className="prod-description-process">
        
                  <h2 className='background-title'>{prod.productTitle}</h2>
                
                <p className="prod-description">
                 
                 {(prod.productDescription.length>290) ?
                  <ReadMore limit={290}>
                  {prod.productDescription}
                  </ReadMore> 
                  :
                  `${prod.productDescription}`}
               
                </p>
                <span className='prod-process-title'>
                  <h2 className='background-title'>طريقة التصنيع</h2>
                </span>
                <p className="prod-process">
             
                {(prod.productProcess.length>290) ?
                  <ReadMore limit={290}>
                  {prod.productProcess}
                  </ReadMore> 
                  :
                  `${prod.productProcess}`}
               

                </p>
              </div>

              <div className="prod-uses-specs">
                <div className="prod-uses">
                  <div className='prod-uses-title'>
                  <h2 className='background-title' >الاستخدامات</h2>
                  </div>
                  <ul>
                    {prod.uses.map((use, index) => (
                      <>
                        <li key={index}>{`-${use}`}</li>
                      </>
                    ))}
                  </ul>
                </div>

                <div className="prod-specs">
                <div className='prod-specs-title'>
                  <h2 className='background-title'>الخصائص</h2>
                </div>
                  <ul>
                    {prod.specs.map((spec, index) => (
                      <>
                        <li key={index}>{`-${spec}`}</li>
                      </>
                    ))}
                  </ul>
                </div>
</div>
              </div>

            </div>
          );
        }
       })}
      


      </div>
    </div>
  )
}
