import React from 'react'
import './Hero.css'
const Hero = () => {
  return (
    <section className="hero-section">
      <div id="heros" className="hero ">
        <div className="hero-h1">
          <h1 className="hero-h1">المعمل الاول من نوعه في العراق</h1>
        </div>
        <div className="hero-paragraphs">
          <p>
            ومن المعامل الاستراتيجية الذي تساهم منتجاته في كثير من الصناعات
            واهمها التعدين, تنقية النفط الخام, المواد الزراعية, السقوف الثانوية,
            مواد البناء{" "}
          </p>

          <p>
            وباستخدام الطريقة المستمرة ومن خلال الافران الدوارة نضمن الحصول على
            اعلى نقاوة لمنتجاتنا{" "}
          </p>
        </div>
        <div className="hero-buttons">
          <a href="#contactus">
            <button className="hero-contactus-button">تواصل معنا</button>
          </a>
          <a href="#about">
            {" "}
            <button className="hero-knowmore-button">اعرف المزيد</button>
          </a>
        </div>
      </div>
      
    </section>
  );
}

export default Hero