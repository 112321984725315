import { useState } from 'react'
import './CreateAd.css'
import { useFireStore } from '../../../hooks/useFirestore'
import { Timestamp } from 'firebase/firestore'


export default function CreateAd({toggleCreate,toggleState}) {
    const {addDocWithoutImage} =useFireStore('ads')


    const [adTitle, setAdTitle] = useState('')
    const [adBody, setAdBody] = useState('')
    const [adDate, setAdDate] = useState('')

    const handleCreateAd=(e)=>{
        e.preventDefault()
        console.log(adTitle,adBody,adDate);
        
        const adInfo={
            adTitle,
            adBody,
            adDate:Timestamp.fromDate(new Date(adDate)),
            isPublished:false
        }
        addDocWithoutImage(adInfo)
        
        toggleCreate(!toggleState)
    }


  return (

    <div className='create-ad-page' >
    <div className="create-ad-form create-news">
        <h3>انشاء اعلان</h3>

        <form onSubmit={handleCreateAd} >
        
        <label htmlFor="ad-head">العنوان</label>
        <input 
        id='ad-head' 
        type="text"
        onChange={(e)=>setAdTitle(e.target.value)}
        value={adTitle}
        required

         />

        <label htmlFor="ad-body">الوصف</label>
        <textarea 
         id="ad-body"
         onChange={(e)=>setAdBody(e.target.value)}
         value={adBody}
         required

         ></textarea>
        
        <label htmlFor="ad-date">تاريخ الاعلان</label>
       
        <input
        type="datetime-local"
        id="ad-date" 
        onChange={(e)=>setAdDate(e.target.value)}
        value={adDate}
        required

        />
        <br />
        <br />
            
        <button className='btn'
        style={{ marginInline:`2rem` }}
          > نشر الاعلان</button>
        <button className='btn' 
        style={{ backgroundColor: `#980048`, color: `white` }}
        type="button" onClick={()=>toggleCreate(!toggleState)} > الغاء</button>

        </form>
    </div>
    </div>
  )
}
