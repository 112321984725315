import './Signup.css'

import React, { useEffect, useState } from 'react'
import { useSignUp } from '../../../hooks/useSignUp'
import { useCollection } from '../../../hooks/useCollection'
import { useAuthContext } from '../../../hooks/useAuthContext'
import NotFound from '../../public-pages/notFoundPage/NotFound'

export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  // const [thumbnail, setThumbnail] = useState(null)
  // const [thumbnailError, setThumbnailError] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)

  const {documents}=useCollection("users")
  const [adminx, setAdminx] = useState("")
  const {user}=useAuthContext()
  
  useEffect(() => {
  if(documents){
      const admin=documents.filter((document)=>{
          let admin
          if(document.admin === true ){
              if(document.id === user.uid){
                setAdminx(document.admin)
              }
           return admin=document
          }
          return admin
      })
  }
  
    return () => {
    
    }
  }, [documents,user.uid])
  const {signUp,isPending,error}=useSignUp()

   
 const checkTrueOrFalse=(e)=>{
    if(e.target.value === "true"){
      setIsAdmin(true)
    }else{
      setIsAdmin(false)
    }
 } 

  const onSubmitHandler = e => {
    e.preventDefault()
    signUp(email, password, displayName,isAdmin);
  }

  // const handleFileChange = e => {
  //   setThumbnail(null)
  //   let selected = e.target.files[0]
  //   console.log(selected);
  //   if(!selected){
  //     setThumbnailError("Please select a file");
  //   return 
  //   }
  //   if(!selected.type.includes("image")){
  //     setThumbnailError("Please select an image");
  //     return 
  //   }
  //   if(selected.size > 100000){
  //     setThumbnailError("Please select an image less than 100kb");
  //     return
  //   }
  //   setThumbnailError(null)
  //   setThumbnail(selected)

  // }

 
  return (
    <div className="signup">
    {adminx ?
    <form  className="auth-form signup-form" onSubmit={onSubmitHandler}>
      <h3>تسجيل مستخدم جديد</h3>
      <label>
        <span>البريد الالكتروني</span>
        <input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </label>
      <label>
        <span>الرمز</span>
        <input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </label>
      <label>
        <span>اسم المستخدم</span>
        <input
          type="text"
          value={displayName}
          onChange={e => setDisplayName(e.target.value)}
        />
      </label>
      <label>
        <span>الصلاحيات</span>
        <select className='btn'
          value={isAdmin}
          onChange={e => checkTrueOrFalse(e)}
        >
          <option value={false}>مستخدم عادي</option>
          <option value={true}>مسؤول</option>
        </select>
      </label>
      
      {!isPending && <button className="btn">تسجيل</button>}
      {isPending && <button className="btn" disabled>جاري التسجيل...</button>}
      {console.log(error)}
      {error && <p className="error">حدث خطا ما</p>}
   
      
    </form>
    :<NotFound/>}
    </div>
  )
}
