import {BrowserRouter as Router,Route,Routes,Navigate} from 'react-router-dom'
import NavBar from './components/NavBar/NavBar'

import './App.css'
import HomePage from './pages/public-pages/home/HomePage'
import NewsPage from './pages/public-pages/news/NewsPage'
import AboutPage from './pages/public-pages/about/AboutPage'
import ProductsPage from './pages/public-pages/products/ProductsPage'
import NotFound from './pages/public-pages/notFoundPage/NotFound'
import Ads from './pages/public-pages/ads/ads'
import Dashborad from './pages/management/Dashboard/Dashborad'
import Project from './pages/management/News/News'
import Create from './pages/management/CreateNews/CreateNews'
import ManageAds from './pages/management/Manage-ads/ManageAds'
import CreateProduct from './pages/management/manage-products/CreateProduct'
import ProductSummary from './pages/management/manage-products/ProductSummary'
import ProductsList from './pages/management/manage-products/ProductsList'
import Signup from './pages/management/Signup/Signup'
import Login from './pages/management/Login/Login'

import PrivateRoutes from './pages/PrivateRoutes'
import PublicRoutes from './pages/PublicRoutes'
import EditAbout from './pages/management/manage-public-pages/EditAbout'





function App() {
 

  return (
      <div className="App">
 
      <Router>
        <Routes>
          <Route element={<PublicRoutes/>}>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/news" element={<NewsPage/>}/>
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/products" element={<ProductsPage/>}/>
          <Route path="/ads" element={<Ads/>}/>

          </Route>
         
          
          <Route element={<PrivateRoutes/>} >
          <Route path='/management/admin/ads' element={<ManageAds/>}/>
          <Route path='/management/admin/createProduct' element={<CreateProduct/>}/>
          <Route path='/management/admin/products' element={<ProductsList/>}/>
          <Route path='/management/admin/products/:id' element={<ProductSummary/>}/>
          <Route path='/management/admin/create' element={<Create/>}/>
          <Route path='/management/admin/about-page' element={<EditAbout/>}/>
          <Route path='/management/admin/project/:id' element={<Project/>}/>
          <Route path="/management/admin/signup" element={<Signup/>}/> 
          <Route path="/management/admin/" element={<Dashborad/>}/> 

          </Route>

          <Route path="/management/admin/login" element={<Login/>}/>
          



          <Route path='*' element={<NotFound/>} />
        </Routes>
      </Router>
      </div>

  );
}

export default App
