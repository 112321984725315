import { useState ,useEffect} from "react"
import { useNavigate } from "react-router-dom"
import { projectAuth,updateDoc,projectFirestore,doc } from "../firebase/config"
import { useAuthContext } from "./useAuthContext"


export const useLogOut=()=>{
    const navigate=useNavigate()
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const {dispatch} = useAuthContext()
   
    const logOut = async ()=>{

        setError(null)
        setIsPending(true)
        //sign user Out
        try {
            await updateDoc(doc(projectFirestore,"users",`${projectAuth.currentUser.uid}`),{
                online:false,
              })

            await projectAuth.signOut()
            dispatch({type:"LOGOUT"})

            if(!isCancelled){
                setIsPending(false)
                setError(null)
            }
            setIsPending(false)
            setError(null)
            navigate("/management/admin/login")
        }catch (err){
            console.log(err);
            if(!isCancelled){
                setIsPending(false)
                setError(null)
            }
        }
    }
    useEffect(() => {
      
    
      return () => {
        setIsCancelled(true)
      }
    }, [])
    
    return {logOut,isPending,error}
}