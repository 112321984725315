import { useState } from "react"
import { timeStamp,Timestamp } from "../../firebase/config"
import { useAuthContext } from "../../hooks/useAuthContext"
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { useFireStore } from '../../hooks/useFirestore'
import Avatar from "../Avatar/Avatar"
import './Comments.css'
export default function NewsComments({news}) {
const {updateDocument,response} =useFireStore("news")
const styleDate=formatDistanceToNow
    const [newComment, setNewComment] = useState('')

    const {user}=useAuthContext()

    const handleSubmit=async (e)=>{
        e.preventDefault()
        const comment={
            displayName:user.displayName,
            photoURL:user.photoURL,
            comment:newComment,
            createdAt: Timestamp.now(),
            id:Math.floor(Math.random()*10000500)
        }
       await  updateDocument(news.id,comment);
       if (!response.error) {
        setNewComment('')
      }
    }


    return (
    <div className="news-comments">
        <h4>الملاحظات</h4>
       <ul>
        {news.comments.length >0 &&news.comments.map(comment=>(
            <li key={comment.id}>
            <div className="news-author">
                <Avatar src={comment.photoURL}/>
                <p>{comment.displayName}</p>
            </div>
            <div className="news-date">
                <p>{styleDate(comment.createdAt.toDate(),[{addSuffix:true}])}</p>
            </div>
            <div className="news-content">
                <p>{comment.comment}</p>
            </div>
            </li>
        ))}
       </ul>
        <form onSubmit={handleSubmit}>
            <label >
                <span>اضف ملاحظة</span>
                <textarea
                style={{resize:`none`}}
                required
                onChange={(e)=>setNewComment(e.target.value)}
                value={newComment}
                ></textarea>
            </label>
            <button className="btn" type="btn"> اضافة الملاحظة </button>
        </form>
    </div>
  )
}
