import { NavLink } from "react-router-dom"
import { useAuthContext } from '../../hooks/useAuthContext'
import { useLogOut } from "../../hooks/useLogOut"
// components
import Avatar from "../Avatar/Avatar"

// styles & images
import "./Sidebar.css"
import DashboardIcon from '../../assets/images/dashboard_icon.svg'
import AddIcon from '../../assets/images/add_icon.svg'
import signOutSVG from '../../assets/images/signOutSVG.svg'
import activitySVG from '../../assets/images/activity_icon.svg'
import { useEffect, useState } from "react"
import { useCollection } from "../../hooks/useCollection"
import { MdManageAccounts } from "react-icons/md"


export default function Sidebar() {
  const { user } = useAuthContext()
  const {logOut,isPending,error} =useLogOut()
   

  const {documents}=useCollection("users")
  const [adminx, setAdminx] = useState("")

  
  useEffect(() => {
  if(documents){
      const admin=documents.filter((document)=>{
          let admin
          if(document.admin === true ){
              if(document.id === user.uid){
                setAdminx(document.admin)
              }
           return admin=document
          }
          return admin
      })
  }
  
    return () => {
    
    }
  }, [documents,user.uid])


  return (
    <div className="sidebar"  >
      <div className="sidebar-content">
        <div className="user">
          <p>مرحبا  {user.displayName}</p>  
        </div>  
        <nav className="links">
          <ul>
            <li>
              <NavLink  to="/management/admin/">
                <img src={activitySVG} alt="dashboard icon" />
                <span>الاخبار</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/management/admin/create">
                <img src={AddIcon} alt="add news icon" />
                <span>اضافة خبر</span>
              </NavLink>
            </li>
            <li>
              <NavLink  to="/management/admin/ads">
                <img src={activitySVG} alt="dashboard icon" />
                <span>الاعلانات</span>
              </NavLink>
            </li>
        
            <li>
              <NavLink  to="/management/admin/products">
                <img src={DashboardIcon} alt="dashboard icon" />
                <span>المنتجات</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/management/admin/createProduct">
                <img src={AddIcon} alt="add product icon" />
                <span>اضافة منتج </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/management/admin/about-page">
                <img src={activitySVG} alt="add product icon" />
                <span>تعديل حول </span>
              </NavLink>
            </li>
            {adminx &&  <li>
              <NavLink to="/management/admin/signup">
                <MdManageAccounts style={{fontSize:`2rem`}}/>
                <span style={{textAlign:`right`}}>اضافة مستخدم </span>
              </NavLink>
            </li>}
            <li>
              
              {isPending && <button className="btn logOutBtn" disabled onClick={logOut}>...جاري تسجيل الخروج </button> }
              {!isPending && <button className="btn logOutBtn" onClick={logOut}>تسجيل خروج</button>}
              {!isPending && <button className="phoneLogOutBtn" onClick={logOut}><img src={signOutSVG} alt="" style={{width:`3rem`}} /></button>}
              {isPending && <button className="phoneLogOutBtn" disabled onClick={logOut}>...</button> }


              {error && <p className="error">حدث خطأ اثناء تسجيل الخروج </p>}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}