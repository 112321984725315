import React, { useState } from "react";
import { useFireStore } from "../../../hooks/useFirestore";
import { Timestamp } from "firebase/firestore";

export default function AdEdit({ adTitle, adBody, adDate, adId ,status}) {

  const {updatenews,deleteDocument}=useFireStore('ads')
  const [isEditingAd, setIsEditingAd] = useState(false);
  const [editAdTitle, setEditAdTitle] = useState('')
  const [editAdBody, setEditAdBody] = useState('')
  const [editAdDate, setEditAdDate] = useState('')


  const handleDeleteAd =()=>{
    deleteDocument(adId)
  }


  const handleEdit = (e) => {
    e.preventDefault();
    if(isEditingAd){
        setIsEditingAd(false)
    }
    const editedAd ={
        adTitle:editAdTitle,
        adBody:editAdBody,
        adDate:Timestamp.fromDate(new Date(editAdDate))
    }
    updatenews(adId,editedAd)
  };
  
  const toggleEditing =()=>{

    let text = adDate.toDate().toISOString();
    let changedHour = parseInt(text.split("T")[1].split(":")[0])+3;
    if(changedHour<10){
        console.log(changedHour);
        changedHour="0"+changedHour
    }
    console.log(changedHour);
    const changedTime =text.replace(text.split("T")[1].split(":")[0],changedHour)
    console.log(changedTime);
    setEditAdTitle(adTitle)
    setEditAdBody(adBody)
    setEditAdDate(changedTime.split(":00.000")[0])
    setIsEditingAd(prevState=>!prevState)

  }


  const handleUnPublish=()=>{
    updatenews(adId,{isPublished:false})
   }

   const handlePublish=(e)=>{
    updatenews(adId,{isPublished:true})
   }
  return (
    <div className="ad-box">
      {!isEditingAd && (
        <div>
          <h3>{adTitle}</h3>
          <p>{adBody}</p>

          <h4>{adDate.toDate().toLocaleString("ar-IQ")}</h4>
          <div className="edit-ad-btns">

          <button 
            className="btn" 
            style={{ backgroundColor: `#980048`, color: `white` }}
            onClick={handleDeleteAd}
            >حذف </button>

            <button
              className="btn"
              onClick={toggleEditing}
            >
              تعديل
            </button>
          {!status && 
          <button
           className="btn"
           onClick={handlePublish} 
          >نشر</button>
          }
          {status && 
          <button
           className="btn"
           style={{ backgroundColor: `#980048`, color: `white` }}
           onClick={handleUnPublish} 
          >عدم النشر</button>
          }
          
          </div>
        </div>
      )}

      {isEditingAd && (
        <div className="edit-ad-form">
          <form onSubmit={handleEdit}>
            <label htmlFor="adTitle">العنوان</label>
            <input
              type="text"
              onChange={(e) => setEditAdTitle(e.target.value)}
              value={editAdTitle}
              id="adTitle"
              required

            />
            <label htmlFor="adBody">الوصف</label>
            <textarea
              id="adBody"
              onChange={(e) => setEditAdBody(e.target.value)}
              value={editAdBody}
              required
            ></textarea>
            <label htmlFor="adDate">التاريخ</label>
            <input
              onChange={(e) => (
                setEditAdDate(e.target.value)
                )
                }
              value={editAdDate}
              type="datetime-local"
              id="adDate"
              required
            />
            <div>
              <button
                className="btn"
                style={{ backgroundColor: `#980048`, color: `white` }}
                onClick={() => setIsEditingAd(!isEditingAd)}
                type="button"
              >
                الغاء
              </button>
              <button className="btn" >
                حفظ التعديل
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
