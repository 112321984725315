import React from 'react'



const noFoundStyle={
  position:'absolute',
  display:'grid',
  gap:'1.5rem',
  justifyContent:'center',
  textAlign:'center',
  fontSize:'1.5rem',
  top:'35%',
  left:'40%',
  width:'20vw',
  height:'10vh'
}
export default function NotFound() {
  return (
    <div style={noFoundStyle}>
        <h1>404</h1>
        <h2>الصفحة غير موجودة</h2>
    <a style={{textDecoration:'none'}} className='btn' href="/">عودة الى الصفحة الرئيسية</a>
    </div>
  )
}
