import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";

import { useFireStore } from "../../../hooks/useFirestore";
import useUpdateNews from "../../../hooks/useUpdateNews";
import { useDocument } from "../../../hooks/useDocument";
import { useStorage } from "../../../hooks/useStorage";

import React from "react";
import { useCollection } from "../../../hooks/useCollection";

export default function ProductSummary() {
  const { id } = useParams();
  const { user } = useAuthContext();
  const { document: prod, error, isPending } = useDocument("products", id);
  const {documents}=useCollection("users")

  const { deleteDocument, response, updatenews } = useFireStore("products");
  const navigate = useNavigate();

  const {
    uploadImageAndCreateDocument,
    imageUploadProgress,
    isPending: isImageUploading,
  } = useStorage("products", "productsImage");

  const { deleteNewsImage,uploadNewNewsImage } = useUpdateNews();

  //SECTION states
  const [productImage, setProductImage] = useState(null);
  const [productImageError, setProductImageError] = useState(null);
  const [productTitle, setProductTitle] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productProcess, setProductProcess] = useState("");
  const [uses, setUses] = useState([""]);
  const [specs, setSpecs] = useState([""]);
  const [isEditing, setIsEditing] = useState(false);

  const [isEditingImage, setIsEditingImage] = useState(false);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setProductTitle(prod.productTitle);
      setProductDescription(prod.productDescription);
      setProductProcess(prod.productProcess);
      setUses(prod.uses);
      setSpecs(prod.specs);
    }
  };
  const [adminx, setAdminx] = useState("")

  useEffect(() => {
  if(documents){
      const admin=documents.filter((document)=>{
          let admin
          if(document.admin === true ){
              if(document.id === user.uid){
                setAdminx(document.admin)
              }
           return admin=document
          }
          return admin
      })
  }
  
    return () => {
    
    }
  }, [documents,user.uid])


  //SECTION Image Change
  const toggleImageEdit = () => {
    setIsEditingImage((prevIsEditingImage) => !prevIsEditingImage);
    if (productImage) {
      setProductImage(null);
    }
  };
  const handleFileChange = (e) => {
    setProductImage(null);
    let selected = e.target.files[0];

    if (!selected) {
      setProductImageError("Please select a file");
      return;
    }
    if (!selected.type.includes("image")) {
      setProductImageError("Please select an image");
      return;
    }
    if (selected.size > 4194304) {
      setProductImageError("Please select an image less than 4mb");
      return;
    }
    setProductImageError(null);
    setProductImage(selected);
  };
  const handleProductTitle = (e) => {
    setProductTitle(e.target.value);
  };

  //SECTION Product Description
  const handleProductDescription = (e) => {
    setProductDescription(e.target.value);
  };
  //SECTION Product Process
  const handleProductProcess = (e) => {
    setProductProcess(e.target.value);
  };
  //SECTION CHANGE IMAGE
 
  const handleUpdate=(e)=>{
    updatenews(prod.id,{isPublished:true})
   }
   const handleUnPublish=()=>{
    updatenews(prod.id,{isPublished:false})
   }
  const handleSaveNewImage = () => {
    const rando=Math.floor(Math.random()*152634)
    if (productImage !== null) {
      uploadNewNewsImage(
        prod.id,
        "products",
        productImage,
        prod.imgRef,
        `productsImage/${rando}${productImage.name}`
      );
      setProductImage(null);
      setIsEditingImage(false)
    }
  };
  //SECTION DELETE PRODUCT
  const handleDeleteProduct = () => {
    deleteDocument(prod.id);
    deleteNewsImage(prod.imgRef)
    navigate("/management/admin/products");
  };

  //SECTION Product Submit
  const handleProductSubmit = async (e) => {
    e.preventDefault();
    const product = {
      productTitle,
      productDescription,
      productProcess,
      uses,
      specs,
    };
   
    updatenews(prod.id, product)
    setIsEditing(false)
  };
  const handleAdd = () => {
    const abc = [...uses, []];
    setUses(abc);
  };
  const handleChange = (onChangeValue, i) => {
    const inputdata = [...uses];
    inputdata[i] = onChangeValue.target.value;
    setUses(inputdata);
  };
  const handleDelete = (i) => {
    const deleteUses = [...uses];
    deleteUses.splice(i, 1);
    setUses(deleteUses);
  };

  //SECTION Specs handlers
  const handleAddSpecs = () => {
    const oldSpec = [...specs, []];
    setSpecs(oldSpec);
  };
  const handleChangeSpecs = (onChangeValue, i) => {
    const inputdata = [...specs];
    inputdata[i] = onChangeValue.target.value;
    setSpecs(inputdata);
  };
  const handleDeleteSpecs = (i) => {
    const deleteSpec = [...specs];
    deleteSpec.splice(i, 1);
    setSpecs(deleteSpec);
  };

  return (
    <div className="product-summary-page">
      <div className="product-summary" style={{gridTemplateColumns: isEditing && `1fr`}}>
        {isPending && <div>جاري التحميل</div>}
        {!isEditing && !isPending && (
          <>
            <div className="product-sum-image">
              <img src={prod && prod.imageURL} alt="" />
              <br />
              {isEditingImage && !isPending && (
                <>
                  <label htmlFor="files" className="btn">
                    صورة منتج جديدة
                  </label>
                  <input
                    id="files"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: `none` }}
                  />
                  {productImageError && (
                    <p className="error"> {productImageError}</p>
                  )}
                </>
              )}
              <div className="product-sum-btns">

              <button className="btn" type="button" onClick={toggleImageEdit}>
                {!isEditingImage ? "تغيير الصورة" : " الغاء تغيير الصورة"}
              </button>

              {productImage && (
                <button className="btn" onClick={handleSaveNewImage}>
                  حفظ هذه الصورة
                </button>
              )}
              {isPending && (
                <>
                  <div
                    className="progress-bar"
                    style={{
                      width: imageUploadProgress + "%",
                      height: `2em`,
                      backgroundColor: `pink`,
                      transition: `width 4s`,
                    }}
                  ></div>
                </>
              )}
              {adminx && !prod.isPublished && (
                <button
                  className="btn"
                  style={{ backgroundColor: `#980048`, color: `white` }}
                  type="button"
                  onClick={handleUpdate}
                >
                  نشر
                </button>
              )}
              {adminx && prod.isPublished && (
                <button
                  className="btn"
                  style={{ backgroundColor: `#980048`, color: `white` }}
                  type="button"
                  onClick={handleUnPublish}
                >
                  عدم النشر
                </button>
              )}
              {adminx && (
                <button
                  className="btn"
                  style={{ backgroundColor: `#980048`, color: `white` }}
                  type="button"
                  onClick={handleDeleteProduct}
                >
                  حذف
                </button>
              )}
              </div>
             
            </div>

            <div className="product-sum-text">
            <div className="product-sum-gen">
       
                <h2>{prod && prod.productTitle}</h2>
                <div>
                <h3>وصف المنتج</h3>
                <br />
                <p>{prod && prod.productDescription}</p>
                </div>
                
                <div>
                <h3>عملية التصنيع</h3>
                <br />
                <p>{prod && prod.productProcess}</p>
                </div>

                </div>
       

                <div>

                <h3 style={{ marginBottom: `.8rem` }}>الاستخدامات</h3>

                <div className="product-sum-uses-specs">

                  <ul
                    style={{
                      display: `grid`,
                      gap: `1rem`,
                      marginBottom: `1.3rem`,
                    }}
                  >
                    {prod &&
                      prod.uses.map((use, i) => <li key={i}>{`${use}`}</li>)}
                  </ul>

                  <h3 style={{ marginBottom: `.8rem` }}>الخصائص</h3>
                  <ul style={{ display: `grid`, gap: `1rem` }}>
                    {prod &&
                      prod.specs.map((spec, i) => <li key={i}>{`${spec}`}</li>)}
                  </ul>
                  </div>
            
            </div>
            {
                <>
                  <button
                    type="button"
                    className="btn"
                    onClick={toggleEdit}
                  >{`${!isEditing ? "تعديل التفاصيل " : "الغاء"}`}</button>
                </>
              }
            </div>
          </>
        )}

        {isEditing && (
          <>
            <form className="edit-product-form" onSubmit={handleProductSubmit}>
              <div>
                <label>
                  <span>عنوان المنتج</span>
                  <input
                    value={productTitle}
                    onChange={handleProductTitle}
                    type="text"
                    required
                  />
                </label>
                <label>
                  <span>وصف المنتج</span>
                  <textarea
                    value={productDescription}
                    onChange={handleProductDescription}
                    required
                    style={{ resize: `none` }}
                  ></textarea>
                </label>
                <label>
                  <span>عملية التصنيع</span>
                  <textarea
                    value={productProcess}
                    onChange={handleProductProcess}
                    required
                    style={{ resize: `none` }}
                  ></textarea>
                </label>
              </div>
              <div>
                <label>الاستخدامات</label>
                {uses.map((data, i) => (
                  <div key={i}>
                    <label>
                      <span>{`استخدام     ${i + 1}`}</span>
                      <input
                        required
                        value={data}
                        onChange={(e) => handleChange(e, i)}
                      />
                      <button
                        className="btn"
                        type="button"
                        onClick={() => handleDelete(i)}
                      >
                        x
                      </button>
                    </label>
                  </div>
                ))}
                <button
                  className="btn"
                  type="button"
                  onClick={() => handleAdd()}
                >
                  اضف استخدام
                </button>
                <label>الخصائص </label>

                {specs.map((data, i) => (
                  <div key={i}>
                    <label>
                      <span>{`خصيصة ${i + 1}`}</span>
                      <input
                        required
                        value={data}
                        onChange={(e) => handleChangeSpecs(e, i)}
                      />
                    </label>
                    <button
                      className="btn"
                      type="button"
                      onClick={() => handleDeleteSpecs(i)}
                    >
                      x
                    </button>
                  </div>
                ))}

                <button
                  className="btn"
                  style={{ float: `right` }}
                  type="button"
                  onClick={() => handleAddSpecs()}
                >
                  اضافة
                </button>
                {isPending && (
                  <>
                    <button className="btn" type="submit" disabled>
                      جاري التحميل
                    </button>
                  </>
                )}
              </div>

              {
                <>
                  <button
                    type="button"
                    className="btn"
                    onClick={toggleEdit}
                  >{`${!isEditing ? "تعديل التفاصيل " : "الغاء"}`}</button>
                </>
              }
              {!isPending && (
                <button className="btn" type="submit">
                  حفظ التعديلات
                </button>
              )}
            </form>
          </>
        )}

        {error && <div>{error}</div>}
      </div>
    </div>
  );
}
