import {useCollection }from '../../../../../hooks/useCollection'
import ReadMore from '../../../../../components/ReadMore/ReadMore'

import './ProductsSection.css'

import svgRect from './path-61.svg'

export default function ProductsSection() {
   
  const loadingStyle={
    position:'absolute',
    top:'50vh',
    left:'50vw',
    fontSize:'1.3rem'

  }

  const { documents:products, error, isPending } = useCollection('products','createdAt',["isPublished", "==" , true ],null);

  
  return (
    <div>
      <div className="products-home-page">
        <div id="products" className="home-products-header">
          {/* <img className="path-51-icon" alt="" src={svgRect} /> */}
          <h1>المنتجات</h1>
        </div>
        {error && <p className="error">حدث خطأ في تحميل المنتجات</p>}
        {/* {isPending && !error && <div style={loadingStyle}>جاري التحميل </div>} */}

        {products &&
          products.map((prod, index) => {
            if (prod.isPublished) {
              return (
                <div className="home-product-card " key={index}>
                  <div className="home-prod-header-image">
                    <img src={prod.imageURL} alt="" />
                  </div>
                  <div className="home-prod-text-wrapper">
                    <div className="home-prod-description-process">
                        <div className="home-prod-title background-title">
                          <h2>{prod.productTitle}</h2>
                        </div>
                      <p className="home-prod-description">
                        {prod.productDescription.length > 350 ? (
                          <ReadMore limit={350}>
                            {prod.productDescription}
                          </ReadMore>
                        ) : (
                          `${prod.productDescription}`
                        )}
                      </p>
                    </div>

                
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
}
