import React from 'react'
import first from './first-triangle-img.png'
import { useCollection } from '../../../../../hooks/useCollection'

import second from './second-triangle-img.png'
import third from './third-triangle-img.png'
import fourth from './fourth-triangle-img.png'
  import fifth from './fifth-triangle-img.png'
import './KLFHero.css'

const KlfHero = () => {
  const {documents:aboutPage,isPending,error}=useCollection('publicPages')
  
  return (
    <div id='about' className="klf-hero">
  
      <div className="klf-hero-text ">
        <h4 >النورة في كربلاء</h4>
        
        {!isPending && aboutPage ?<p>{aboutPage[0].aboutFactory}</p> :<p>...
        </p>}
        
      </div>
      <div className="klf-hero-img-triangles">
      
      <div className="first-row-triangles  ">
        

            <img alt="" src={first} />
            <img alt="" src={second} />
            <img alt="" src={third} />
        
      </div>

      <div className="second-row-triangles ">
        <img alt="" src={fourth} />
        <img alt="" src={fifth} />
      </div>

    </div>

    </div>
  );
}

export default KlfHero