import { useParams } from 'react-router-dom'
import { useDocument } from '../../../hooks/useDocument'
import NewsSummary from './NewsSummary'
import './News.css'
import NewsComments from '../../../components/ProjectComments/Comments'


export default function Project() {
const {id}=useParams()
const {document:news,error}=useDocument('news',id)
const loadingStyle={
    position:'absolute',
    top:'45%',
    left:'55%',
    fontSize:'1.3rem'

  }
if (error) {
    return <div className='error'>{error}</div>
}  
if (!news) {
    return <div style={loadingStyle}>جاري التحميل ...</div>
}

return (
    <div className='news-details'>
    <NewsSummary news={news}/>
    <NewsComments news={news} />
    </div>
  )
}
