import { useState } from "react"
import { deleteObject, getDownloadURL, projectFirestore, projectStorage, ref, updateDoc, uploadBytesResumable,doc  } from "../firebase/config"


export default function useUpdateNews() {
    const [newNewsImageURL, setNewNewsImageURL] = useState('')
    const [imageUploadProgress, setImageUploadProgress] = useState('')
    //SECTIONto update the img in news
    //! first we need to delete the old Image,
    //* then upload new Image and get its url 
    // ?  Then update the img URL and update the new img path too in the  firestore document 
   
    const deleteNewsImage=(imgToDelPath)=>{
        const imgToDelRef=ref(projectStorage,`${imgToDelPath}`)
        deleteObject(imgToDelRef).then(()=>{
            // console.log("img Deleted");
         }).catch((err)=>{
            console.log(err);
         })
    }
    const uploadNewNewsImage = async (projectId,coll,newNewsImage,imgToDelPath,imgRef,imgCount)=>{
        

       


         //upload new Image


        const uploadPath = ref(projectStorage,imgRef)
        
        const uploadTask =uploadBytesResumable(uploadPath,newNewsImage)

        
        uploadTask.on("state_changed",(snapshot)=>{
            const progress= (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setImageUploadProgress(progress);
           
            switch(snapshot.state){
                case "paused":
                break;
                case "running":
                break;
                default:
                        return snapshot.state
            }
            if(progress>100){
                setImageUploadProgress(0)
            }
        },(error)=>{
            console.log(error);
        },async ()=>{
            deleteNewsImage(imgToDelPath)

          await  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL)=>{
                const docRef=doc(projectFirestore,coll,projectId)
               //change the url in the firestore
               if(imgCount>0){
                updateDoc(docRef,{
                    [`imgRef${imgCount}`]:imgRef,
                    [`imageURL${imgCount}`]:downloadURL
                }).catch((err)=>{
                    console.log(err);
                })
                return
               }
                 updateDoc(docRef,{
                    imgRef:imgRef,
                    imageURL:downloadURL
                }).catch((err)=>{
                    console.log(err);
                })
               
              });
      
        }
        
        );

}
  



return {deleteNewsImage,uploadNewNewsImage,imageUploadProgress,setImageUploadProgress}

}
